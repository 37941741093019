<div class="h-[4px] top-bar"> </div>
<div class="top-header top-bar">Shipment Progress</div>

<div class="p-3 grid gap-2 relative {{mac}}" style=" overflow-y: auto;
height: calc(100vh - 90px);">
  <cl-share-composite-cards
*ngIf="compositeShipmentId"
[compositeShipmentData]="compositeShipmentData"
[shipmentId]="shipmentId"
(refreshShipment)="getCompositeShipmentData()"
(selectedShipment)="onSelectShipment($event)"
></cl-share-composite-cards>
<div *ngIf="!isLoading">
  <cl-share-shipment-details-summary
  [winkedAssetId]="winkedAssetId"
  [shipmentData]="shipmentDetails"
  [isRestricted]="isRestricted"
  *ngIf="shipmentDetails"
  (refreshShipment)="getShipmentDetails()"
  ></cl-share-shipment-details-summary>

  <cl-share-included-assets
  [shipmentData]="shipmentDetails"
  [isRestricted]="isRestricted"
  (sendDataToapply)="getWinkedAssetId($event)" (refreshShipment)="getShipmentDetails()"
  *ngIf="shipmentDetails"
  ></cl-share-included-assets>

  <cl-share-details-map
  [shipmentData]="shipmentDetails"
  [assetData]="shipmentDetails.assetNodes[0]"
  [winkedAssetId]="winkedAssetId"
  [isRestricted]="isRestricted"
  *ngIf="shipmentDetails"
  ></cl-share-details-map>
</div>
</div>
<div *ngIf="isLoading">
  <div class="mt-2 flex justify-center">
    <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
  </div>
</div>
<div class="text-center"  *ngIf="!shipmentDetails && !isLoading && validUrl">No shipment Data found</div>
<footer [ngClass]="shipmentDetails && !isLoading ? 'sticky' : 'absolute' " class="bgcolor27">
  <div class="p-2">
    <img class="mx-auto" [src]="shareUrlLogo" style="height: 35px;">
  </div>
</footer>

