import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { AssetStatus } from './enums/asset-status.enum';
import { ApiProviderService } from "./services/api-provider.service";
import { UserService } from './services/user.service';
import { Asset, AssetBasicInformation, AssetBasicInformationProperties, CdmField, ExtendedAttributes, Shipment } from './types/asset.type';
import { BulkProperty } from './types/bulk-property.type';
import { CharacteristicValue, TaggedAssetCharacteristicValue } from './types/characteristic-value.type';
import { MeasureType, WebUIParsedConfig } from './types/measure.type';
import { Characteristic, SensorType, Service, WebUIConfig } from './types/sensor-type.type';
import { SensorBasicInformation } from './types/sensor.type';
export type Maps = typeof google.maps;


@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService,
    private _userService: UserService
  ) {}
  getSharedDetails(id: any) {
    let res = this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/share/decrypt/${id}`,
      { observe: 'response' }
    );
    return new Observable((obs:any) => {
      res.subscribe((response: any) => {
        this.apiProvider.setToken(response.headers.get('token'));
        //localStorage.setItem('token', this.token);
        obs.next(response);
        obs.complete();
      });
    });
  }
  getShipmentDetails(id,type) {
    return this.http.get(
        `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/${id}/?type=${type || ''}`
    );
  }
  getCompositeShipmentDetails(shipmentID: string, shipmentType: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/composite/${shipmentID}?type=${shipmentType || ''}`
    );
  }
  refreshShipment(shipmentId:string, shipmentType:string){
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/composite/subShipment/${shipmentId}/refresh/?type=${shipmentType || ''}`,{})
  }
  getShipmentFilters() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/shipmentFilters`
    );
  }
  getTagsList(payload) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/tag/list?includeShipmentInfo=false`,
      payload
    );
  }
  applyAndRemoveManageTags(obj: any, shipmentId) {
    let payload = {"tags":obj.tags,"shipmentIds":shipmentId}
    return this.http.put(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/applyTags`,
      payload
    );
  }

  createNewTag(newtagname) {
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/tag`, {
      tagName: newtagname,
    });
  }
  applyRuleSet(payload) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/bulk/ruleset`,
      payload
    );
  }
  terminateAsset(payLoad) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/taggedasset/status/terminate`,
      payLoad
    );
  }
  completeAsset(payLoad) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/taggedasset/status/complete`,
      payLoad
    );
  }
  getSensorDetails(sensorId) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor${sensorId}`
    );
  }
  bindAsset(payLoad) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/graph/taggedasset/bind?assetIdType=id`,
      payLoad
    );
  }
  unbindAsset(taggedAssetId: string,shipmentId: string) {
    const headerDict = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset/${taggedAssetId}?shipmentId=${shipmentId}`;
    return this.http.delete<any>(url, { headers: new HttpHeaders(headerDict) });
  }
  getQuery(queryText: string, key?: string) {
    let params = {
      globalQueryText: key ? key : '',
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: 'clfMappingType',
          queryText: queryText,
          queryOperator: 'should',
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
          ],
        },
      ],
    };

    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }
  getSensors(key?: string) {
    let params = {
      scrollId: '',
      scrollSize: 20,
      globalQueryText: key ? key : '',
      searchQueries: [
        {
          fieldName: 'clfMappingType',
          queryText: 'Tracker',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: [
            {
              fieldName: 'status',
              queryText: 'READY',
              queryOperator: 'should',
            },
          ],
        },
      ],
    };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }
  getCategoris() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/categories`
    );
  }
  getRouts(key?: string) {
    let params = {
      globalQueryText: key ? key : '',
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: 'clfMappingType',
          queryText: 'route',
          queryOperator: 'should',
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: 'dynamicRoute',
              queryText: 'true',
              queryOperator: 'must_not',
            },
          ],
        },
      ],
    };

    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }
  getAreas() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/areas`
    );
  }
  getCarrierCodes() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/carrierCode`
    );
  }
  getFilters() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/filter`
    );
  }
  getSensorProfiles() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/2/intransit/eventprofiles?onlyActiveProfiles=true`
    );
  }
  getCustomerNames(string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/search/term/baseClass/shipment/custName?textSearchTerm=` + string
    );
  }
  getShipperNames(string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/search/term/baseClass/shipment/shipperName?textSearchTerm=` +
        string
    );
  }
  getUserTimezoneList() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}resources/data/timezone.json`
    );
  }
  getSensorTages(string: any) {
    let payload = {
      searchFields: {
        text: string,
        status: 'READY',
      },
      outFields: ['id'],
    };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/search/sensortags`,
      payload
    );
  }
  getCoustomerCodes(string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/search/term/baseClass/shipment/custCode?textSearchTerm=` + string
    );
  }
  getIventorys(payload: any) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/filter`,
      payload
    );
  }
  getRuleSetByTenant() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/ruleset/bytenant/${this._userService.getEffectiveTenantId()}`
    );
  }
  getRuleSetProfile(ruleId: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/ruleset/profile/${ruleId}`
    );
  }
  getInventoryAssets(payload) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/asset/inventory`,
      payload
    );
  }
  getAssetTypes() {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/search/navigator/aggregate?aggField=typeId;monitoredStatus&baseClass=asset`,
      {}
    );
  }
  getAllAssetTypes() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/graph/category/type/asset`
    );
  }

  globalSearch<T = any>(searchParams, queryParams = {}) {
    const url = `${this.apiProvider.getAPIUrl(
      'clfgraphapp'
    )}api/search/navigator/query`;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(url, searchParams, { headers: headers, params: queryParams })
      .pipe(
        map((res) => {
          return res as T;
        })
      );
  }

  // getting shipment logger for characterstic data
  getShipmentLogger(args: any) {
    let queryParams = new HttpParams();
    Object.keys(args).forEach(function (k) {
      queryParams = queryParams.append(k, args[k]);
    });
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/data/shipmentLogger`,
      { params: queryParams }
    );
  }

  // to get addresses for bulk geocodes
  bulkReverseCoding(coordinates: any[]) {
    let postBody = { coordinates: coordinates };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/geo/bulk-reverse-coding`,
      postBody
    );
  }

  //share email notification
  sendEmail(payload) {
    let requestHeaders = new HttpHeaders().append('application', 'dvp2');
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/share/emailNotification`,
      payload,
      {
        headers: requestHeaders,
      }
    );
  }
  //// Address auto complete

  private getScriptSrc(callback: string): string {
    interface QueryParams {
      [key: string]: string;
    }
    const query: QueryParams = {
      v: '3',
      callback,
      key: 'AIzaSyBFrngKBmi7UwDVsNcRmOPnOVpaUbJYbn0',
      libraries: 'places',
    };
    const params = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');
    return `//maps.googleapis.com/maps/api/js?${params}&language=en`;
  }

  createShipment(payload: any, type: 'standard' | 'parcel', isDraft: boolean) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/${type}/${
        isDraft ? '?isDraft=true' : ''
      }`,
      payload
    );
  }

  updateShipment(
    payload: any,
    shipmentID: string,
    type: 'standard' | 'parcel',
    isDraft: boolean
  ) {
    return this.http.put(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${type}/${shipmentID}/${isDraft ? '?isDraft=true' : ''}`,
      payload
    );
  }
  getEstimateArrival(routeid: string, departureDate: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('routeId', routeid);
    queryParams = queryParams.append('departureDate', departureDate);
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/route/computeETAByRoute`,
      { params: queryParams }
    );
  }
  getAwbData(id: any) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/carrier-tracking/air-cargo/` + id
    );
  }
  getShipmentNames(string: string, fieldType: string) {
    let payload = {
      globalQueryText: string,
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: 'baseClass',
          queryText: 'Shipment',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: fieldType,
              queryText: string,
              queryOperator: 'must',
            },
          ],
        },
      ],
    };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      payload
    );
  }

  getShipmentData(id) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/` + id
    );
  }
  getShipmentExcursions(shippmentId: any, taggedAssetId: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/violations/` +
        shippmentId +
        '/' +
        taggedAssetId
    );
  }
  getShipmentActualRoute(obj) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/data/shipmentLogger?capabilities=GPS_SERVICE:GeoPoint&from=` +
        obj.startDate +
        '&samplingLimit=500&shipmentid=' +
        obj.shipmentId +
        '&taggedAssetIds=' +
        obj.taggedAssetId +
        '&to=' +
        obj.endDate
    );
  }

  terminateShipment(shipmentID: string) {
    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${shipmentID}/status/terminate/`,
      {}
    );
    return this.makeHttpRequest(httpCall);
  }

  completeShipment(shipmentID: string) {
    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${shipmentID}/status/complete/`,
      {}
    );
    return this.makeHttpRequest(httpCall);
  }

  deleteShipment(shipmentID: string) {
    const httpCall = this.http.delete(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${shipmentID}`,
      {}
    );
    return this.makeHttpRequest(httpCall);
  }

  getAssetMileStones({ assetID, from, to }) {
    const httpCall = this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/data/carriercheckpoints/${assetID}?from=${from}&to=${to}`
    );
    return this.makeHttpRequest(httpCall);
  }

  getZoneIdByAreaId(areaId: string) {
    const httpCall = this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/area/${areaId}/zoneid`
    );
    return this.makeHttpRequest(httpCall);
  }

  private makeHttpRequest(httpCall): Promise<[response: any, error: any]> {
    return new Promise((resolve, reject) => {
      return httpCall.subscribe(
        (res) => resolve([res, null]),
        (err) => resolve([null, err])
      );
    });
  }
  getFlightDeparture(payload, startDate: string, endDate: string) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/carrier/FLIGHT/carrierInfo/${startDate}/${endDate}`,
      payload
    );
  }
  getFlighAreaDetails(areaID: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/area/${areaID}`
    );
  }

  getViolations(shipmentId: string, taggedAssetId: string): Promise<any> {
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/violations/${shipmentId}/${taggedAssetId}`
    );
    return firstValueFrom(obs$);
  }

  getCurrentLocationOfAsset(latlang) {
    const headers: any = {
      'Content-Type': 'application/json',
      withCredentials: true,
      secure: false,
    };
    let url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      latlang +
      '&key=AIzaSyBFrngKBmi7UwDVsNcRmOPnOVpaUbJYbn0';
    return this.http.get<any>(url, { headers: headers });
  }
  moveToIntransit(value){
    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/taggedasset/status/intransit`,
      value
    );
    return httpCall
  }
  deleteAsset(payLoad){
    const headers: any = {
      'Content-Type': 'application/json',
    };
    return this.http.delete<any>(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/asset/bulk`, { headers: headers , body:payLoad}
    );
  }
  getShipmentType(id: string) {
    let payLoad = {ids:id}
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/type`, payLoad
    );
    return obs$;
  }
}
