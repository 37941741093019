import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AlertIcon } from 'projects/clf-share/src/types/measure.type';


@Component({
  selector: 'cl-sh-alert-icon',
  templateUrl: './sh-alert-icon.component.html',
  styleUrls: ['./sh-alert-icon.component.scss']
})
export class ShAlertIconComponent implements OnInit, OnChanges {
  @Input() alertIconObject: AlertIcon;
  formattedMeasure: string;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.alertIconObject) {
      this.alertIconObject.measure = this.alertIconObject.measure.toLowerCase().replace(/\s/g, "")
    }
  }


}