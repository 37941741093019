import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as FileSaver from 'file-saver';
import { Observable } from "rxjs";
import { filter } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { PersistenceService } from './sh-persistence.service';

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  mouseGrid: any;

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private persistenceStore: PersistenceService,
    @Inject(LOCALE_ID) private locale: string) {}

  getUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0;
  }
  setMouseGrid(grid: any) {
    this.mouseGrid = grid;
  }
  getMouseGrid(grid: any) {
    return this.mouseGrid;
  }

  isJson(item: any) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }
  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Capitalize and replace underscores with spaces
  formatForDisplay(str: string) {
    if (!str || !str.length) {
      return "";
    }
    let stringArr = str.split("_");
    return stringArr
      .map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      })
      .join(" ");
  }
  /**
   * Shows message in material snackbar
   */
  showMessage(message: string, action: string = "", duration = 3000) {
    return this._snackBar.open(message, action, { duration });
  }


  generateAssetsCSV(numAssets = 20) {
    let names = ["Wrench", "Multimeter", "SolderingIron", "3DPrinter"];
    let types = ["Quasi-Fixed Equipment", "Tooling", "Mobile Equipment", "Portable Equipment"];
    let data = "name,type,externalId,sku,cost,warehouseLocation,condition,manufacturer,maintenanceDate,qrCode\n";

    for (let i = 0; i < numAssets; i++) {
      const rand = Math.round(Math.random() * 1000);
      const cost = Math.floor(Math.random() * 1000) / 100;
      const nameIndex = Math.ceil(Math.random() * names.length - 1);
      const typeIndex = Math.ceil(Math.random() * types.length - 1);
      const name = names[nameIndex] + rand;
      const d = new Date().valueOf();

      data += `${name}, ${types[typeIndex]}, ${name},${this.getUUID()},${cost},,Good, Ericsson,${d},,\n`;
    }

    const fileName = `assets_csv${Math.round(Math.random() * 1000)}.csv`;
    console.log(data);
    FileSaver.saveAs(data, fileName);
  }

  generateUsersCSV(numAssets = 20) {
    let names = ["FieldTech", "User", "Affiliate", "Technician"];
    let regions = ["SouthWest", "SouthEast", "MidWest", "NorthEast", "NorthWest"];
    let data = "login_id,name,role,assigned_location,email,phone,reporting_region,manager,manager_email\n";

    for (let i = 0; i < numAssets; i++) {
      const rand = Math.round(Math.random() * 1000);
      const nameIndex = Math.ceil(Math.random() * names.length - 1);
      const regionIndex = Math.ceil(Math.random() * regions.length - 1);
      const name = names[nameIndex] + rand;
      const len = 10;
      const phone = Math.random().toFixed(len).split(".")[1];

      data += `${name}, ${name}, 'Field Tech', ${regions[regionIndex]}, 'egrotke@cloudleaf.io', ${phone}, ${regions[regionIndex]}, 'manager', 'egrotke@cloudleaf.io'\n`;
    }

    const fileName = `users_csv${Math.round(Math.random() * 1000)}.csv`;
    console.log(data);
    FileSaver.saveAs(data, fileName);
  }

  dwellTimeFormatter (time, includeSec = true, isHumanReadable = true, roundOffSeconds = false, outputLength = 0): string {
    if (isNaN(time)) return "--";
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    if (roundOffSeconds) {
      if (seconds >= 30 && seconds < 60) {
        minutes = minutes + 1;
      }
    }

    let _formattedTime = "";
    if (isHumanReadable) {
      if (minutes > 0) {
        _formattedTime = this.formatTimeString(minutes, "min");
      }

      if (hours > 0) {
        _formattedTime =
        this.formatTimeString(hours, "hr") + "., " + _formattedTime;
      }
      if (days > 0) {
        _formattedTime =
        this.formatTimeString(days, "day") + "., " + _formattedTime;
      }

      if (includeSec) {
        if (minutes > 0) {
          _formattedTime += "., ";
        }
        _formattedTime += this.formatTimeString(seconds, "sec");
      } else if (days + hours + minutes === 0) {
        if (roundOffSeconds) {
          _formattedTime = "0 mins";
        } else {
          _formattedTime = this.formatTimeString(seconds, "sec");
        }
      }

      if (outputLength) {
        let formatArr = _formattedTime.split('.,');
        if (formatArr.length > outputLength) {
          _formattedTime = formatArr.slice(0, outputLength).join('.,');
        }
      }
    } else {
      _formattedTime =
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes);
      if (days > 0) {
        _formattedTime = days + " days " + " " + _formattedTime;
      }
      if (includeSec) {
        _formattedTime += ":" + (seconds > 9 ? seconds : "0" + seconds);
      } else if (days + hours + minutes === 0) {
        if (roundOffSeconds) {
          _formattedTime = "0 mins";
        } else {
          _formattedTime += seconds > 9 ? seconds : "0" + seconds;
        }
      }
    }

    return _formattedTime;
  };

  formatTimeString(timeVal, unit) {
    let _formatted = "",
      _preformatted = timeVal < 10 ? "0" + timeVal : timeVal;
    if (timeVal > 1) {
      _formatted = _preformatted + " " + unit + "s";
    } else if (timeVal === 1) {
      _formatted = _preformatted + " " + unit;
    } else if (timeVal === 0) {
      _formatted = timeVal + " " + unit;
    }
    return _formatted;
  };

  transformDate(date: string | number | Date, fmt: string) {
    return date ? formatDate(date, fmt, this.locale) : '';
  }

  getSelectedElementsFromArray(list: any[], keys: any[]) {
    keys = keys.filter(key => key !== 'selected');
    let subArray = list.map(item => {
      const obj = {};
      keys.forEach(key => {
        obj[key] = item[key];
      });
    return obj;
    });
    return subArray;
  }

  updateColumnsList(page: string, columns: any[]) {
    let rawColumns: any = [...columns];
    let gridColumns = [];
    let storeColumnsList = this.persistenceStore.getGridColumns(page);
    if (storeColumnsList.length) {
      let filteredColumns = [];
      rawColumns.forEach(item => {
        let index = storeColumnsList.findIndex(column => column === item.name);
        if (index > -1) {
          item.visible = true;
          filteredColumns.push(item);
        } else {
          item.visible = false;
        }
      });
      gridColumns = [...filteredColumns];
    } else {
      gridColumns = rawColumns.filter((col: any) => col.visible);
    }
    return {
      gridColumns,
      rawColumns
    }
  }
  chartColors () {
    //keeping the old colors for a while, just in case.
    //return ['#08306b', '#08306b', '#08519c','#2171b5', '#4292c6', '#6baed6', '#9ecae1', '#c6dbef', '#deebf7', '#f7fbff'];
    // 72 color pallete
    return [
      "#51a5de",
      "#822FB5",
      "#B5BCC2",
      "#004EA7",
      "#A565DC",
      "#0c602f",
      "#BD10E0",
      "#0071c1",
      "#700a01",
      "#d63b08",
      "#a9d608",
      "#0dba55",
      "#37c6c6",
      "#470600",
      "#84CCE7",
      "#7c241c",
      "#b73a67",
      "#2EB0DF",
      "#9b2d85",
      "#0094d0",
      "#7f7705",
      "#8327ad",
      "#004EA7",
      "#844d9e",
      "#2110a5",
      "#83d608",
      "#403593",
      "#0a4dd3",
      "#d66808",
      "#043596",
      "#0288db",
      "#d60842",
      "#00badb",
      "#1e8091",
      "#0cccb8",
      "#350c87",
      "#06a392",
      "#d6086b",
      "#028739",
      "#d6c108",
      "#28b515",
      "#7103a5",
      "#138e02",
      "#08a5d6",
      "#65ba0b",
      "#d60898",
      "#9db70b",
      "#0849d6",
      "#b7ab03",
      "#7f6405",
      "#4c870e",
      "#c49a07",
      "#d6d608",
      "#0879d6",
      "#e07a0d",
      "#5308d6",
      "#d6cb08",
      "#cc5c16",
      "#2308d6",
      "#6b7c07",
      "#bd08d6",
      "#45d608",
      "#d68708",
      "#d63e08",
      "#c4d608",
      "#081cd6",
      "#08d66f",
      "#7c08d6",
      "#08d2d6",
      "#9f08d6",
      "#bdd608",
      "#d608cb",
      "#08d63e",
      "#d60823",
      "#08d69f",
      "#d6a908"
    ];
  };

  timeStampUtil(time) {
    let currentDate = moment().format("MM/DD/YYYY");
      let givenDate = moment
        .utc(+time)
        .local()
        .format("MM/DD/YYYY");

      if (currentDate === givenDate) {
        return moment
          .utc(+time)
          .local()
          .format("HH:mm:ss");
      } else {
        return moment
          .utc(+time)
          .local()
          .format("MM/DD/YYYY h:mm:ss a");
      }
  }

  getBarColor(type: string) {
    const colorsObj = {
      'Ready To Ship': '#004ea7',
      'In-Transit': '#0094d0',
      'At Destination': '#76ddfb',
      Exception: '#82b8e2',
      Total: '#2eb0df',
      'shock:rxshockmagnitude': '#004ea7',
      '37c7bb40-b06a-11e3-8000-b70f3ab862a4:37c7bba1-b06a-11e3-8000-b70f3ab862a4':
        '#0094d0',
      'door:doorstatus': '#76ddfb',
      'vibration:rxvibration': '#82b8e2',
      'tilt:rxtiltmagnitudex': '#2eb0df',
      'pressure:rxpressure': '#031BC4',
      '37c7bb40-b06a-11e3-8000-b70f3ab862a4:ambienttemp': '#B7EBFE',
      'complex:dooropenatdestination': '#7EDAFC',
      'tilt:rxtiltmagnitudey': '#0697CC',
      'tilt:rxtiltmagnitudez': '#01BBFF',
      '180f:2a19': '#012651',
      'shock:rxshock': '#033D80',
      'aa20:aa21': '#166FD4',
      'light:rxlight': '#4193F1',
      'tilt:rxtilt': '#7DB8FC',
      '37c7bb40-b06a-11e3-8000-b70f3ab862a4:cargotemp': '#B1D6FF',
      'complex:dooropenatorigin': 'D5E9FF',
    };

    return colorsObj[type] || '#2879DE';
  }


  getTimezone(dateString: string) {
    const date = new Date(dateString);
    const timezoneOffset = date.getTimezoneOffset();
    const hours = Math.floor(Math.abs(timezoneOffset) / 60);
    const minutes = Math.abs(timezoneOffset) % 60;
    const sign = timezoneOffset >= 0 ? '-' : '+';
    const timezone = `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return timezone;
  }

}
