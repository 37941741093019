import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ApiProviderService } from './api-provider.service';
import { AssetBasicInformation } from '../types/asset.type';
import { CharacteristicValue, TaggedAssetCharacteristicValue } from '../types/characteristic-value.type';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private http: HttpClient, private apiProvider: ApiProviderService) { }
  getAssetDetails(assetId: string) : Promise<AssetBasicInformation>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/${assetId}`);
    return firstValueFrom(obs$);
  }
  getAssetExtendedAttributesConfiguration(assetType : string): Promise<any>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/catalog/${assetType}`);
    return firstValueFrom(obs$);
  }
  getTaggedAssetCharacteristicValues(taggedAssetId: string): Promise<CharacteristicValue[]>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${taggedAssetId}`);
    return firstValueFrom(obs$);
  }
  getTaggedAssetCharacteristicValuesByRange(taggedAssetId: string, sid: string, cid: string, from: number, to: number, sampleSize: number): Promise<TaggedAssetCharacteristicValue>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${taggedAssetId}/${sid}/${cid}?from=${from}&to=${to}&limit=${sampleSize}`);
    return firstValueFrom(obs$);
  }
  getLocationVisits(assetId: string, dateRange?: any) {
    let params = {};
    if (!dateRange) {
      var dt = new Date();
      params = {
        from: new Date(dt.setMonth(dt.getMonth() - 1)).getTime(),
        to: (new Date()).getTime(),
      }
    } else {
      params = dateRange;
    }
    return this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/peripheral/${assetId}/visits`, {params});
  }
  getTaggedAssetLastValidHandOff(taggedAssetId: string): Promise<any>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/stream/${taggedAssetId}/lastvalidhandoff`);
    return firstValueFrom(obs$);
  }

  getAdditionalAssetDetails(assetId: string){
    const obs$ =  this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/types/asset/objects/${assetId}`);
    return firstValueFrom(obs$);
  }
}
