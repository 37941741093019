import { Injectable } from '@angular/core';
import _ from 'lodash';

import { BehaviorSubject } from 'rxjs';

import { AssetService } from './asset.service';
import { SensorService } from './sensor.service';
import { SensorUtilService } from './sensor-util.service';
import { AssetStatus } from '../enums/asset-status.enum';
import { Asset, AssetBasicInformation, AssetBasicInformationProperties, CdmField, ExtendedAttributes, Shipment } from '../types/asset.type';
import { Sensor, SensorBasicInformation } from '../types/sensor.type';
import { CharacteristicValue } from '../types/characteristic-value.type';
import { MeasureType } from '../types/measure.type';
import { SensorType } from '../types/sensor-type.type';

@Injectable({
  providedIn: 'root'
})
export class AssetUtilService {
  assetStaticProperties : string[] = ['externalId', 'type', 'status', 'state','assetStatus', 'sensorId', 'organization', 'qrCode', 'sku', 'category', 'cost', 'condition', 'lifecycleStatus', 'checkedoutBy', 'checkedoutToUserId', 'checkedoutTo', 'checkedoutAt', 'fromParty', 'toParty', 'lastCustodyAuditAt', 'custodyOperationAt', 'manufacturer', 'warehouseLocation', 'maintenanceDate', 'createdAt', 'createdBy', 'modifiedAt', 'modifiedBy', 'monitoredStatus', 'imageURL', 'name', 'custodyStatus', 'itemCategory', 'qrBarCode', 'lastCustodyStatusUpdateAt'];
  constructor(private assetService : AssetService
    , private sensorService: SensorService
    , private sensorUtilService: SensorUtilService) { }

  baseObject = new BehaviorSubject<AssetBasicInformation>(null);
  getBaseAsset() {
    return this.baseObject.asObservable();
  }

  getAsset(assetId: string, previousAssetDetails?:any): Promise<Asset>{
    return new Promise<Asset>((resolve)=>{
      let assetObject = {id : assetId, externalId : '', name: '', status : null };
      if(previousAssetDetails) {
        //this.baseObject.next(previousAssetDetails);
        this.getExtraDetails(previousAssetDetails, assetObject, resolve);
      } else {
        this.assetService.getAssetDetails(assetId)
        .then((aResponse: AssetBasicInformation)=> {
          this.baseObject.next(aResponse);
          this.getExtraDetails(aResponse, assetObject, resolve);
        })
        .catch(()=>{
          resolve(assetObject);
        });
      }

    });
  }

  private getExtraDetails(aResponse:AssetBasicInformation, assetObject:any, resolve) {
    if(aResponse?.type){
      Promise.all([
        this.assetService.getAssetExtendedAttributesConfiguration(aResponse.type),
        this.assetService.getAdditionalAssetDetails(assetObject.id)
      ])
      .then((bResponse: [ExtendedAttributes, any])=>{
         let shipmentDetails = bResponse[1]?.hits[0]?.shipmentList && bResponse[1]?.hits[0]?.shipmentList[0];
         this.getTaggedAssetDataForAsset(aResponse, bResponse[0], resolve, shipmentDetails);
      })
      .catch(()=>{
        this.getTaggedAssetDataForAsset(aResponse, null, resolve, null);
      });
    }else{
      this.getTaggedAssetDataForAsset(aResponse, null, resolve, null);
    }
  }

  private getTaggedAssetDataForAsset(aResponse: AssetBasicInformation, bResponse: ExtendedAttributes, resolve: (value: Asset | PromiseLike<Asset>) => void, shipment?: Shipment) {
    if (aResponse?.properties?.sensorIds && aResponse?.properties?.sensorIds.length > 0) {
      let sensorsCalls = []
      aResponse.properties.sensorIds.forEach(sId=>{
        sensorsCalls.push(this.sensorService.getSensorInformation(sId))
      })
      Promise.all(sensorsCalls).then(resps=>{
        const sensors = [...new Set(resps.filter(res => res?.properties?.type).map(e=> e.properties.type))].sort().join(',');
        Promise.all([
          this.assetService.getTaggedAssetCharacteristicValues(aResponse.properties.taggedAssetId),
          this.sensorService.getSensorTypeDetails(sensors)
        ])
          .then((response: [CharacteristicValue[], SensorType]) => {
            resolve(this.getAssetObject(aResponse, sensors, response[0], response[1], null, bResponse.cdmFields, shipment));
          })
          .catch(() => {
            resolve(this.getAssetObject(aResponse, sensors, null, null, null, bResponse.cdmFields, shipment));
          });
      }).catch(() => {
        resolve(this.getAssetObject(aResponse));
      });

    } else {
      resolve(this.getAssetObject(aResponse, null, null, null, null, bResponse.cdmFields, shipment));
    }
  }

  getSensorDetailsAsset(sensor: Sensor): Promise<Asset>{
    return new Promise<Asset>((resolve)=>{
      Promise.all([
        this.assetService.getAssetDetails(sensor.assetId),
        this.assetService.getTaggedAssetCharacteristicValues(sensor.asset.taggedAssetId)
      ])
      .then((response: [AssetBasicInformation, CharacteristicValue[]])=>{
        resolve(this.getAssetObject(response[0], sensor?.type, response[1], null, sensor?.asset?.measures));
      })
      .catch(()=>{
        resolve(sensor.asset);
      });
    });
  }

  private getAssetLastLocation(properties : AssetBasicInformationProperties):string{
    let area: string, building: string, temp: string;
    if(properties?.lastAreaName !== 'Out Of Coverage'){
      area = properties.lastAreaName;
    }else{
      area = null;
    }
    if(properties?.lastBuildingName !== 'Out Of Coverage'){
      building = properties.lastBuildingName;
    }else{
      building = null;
    }
    if(area){
      // Fix for "null, area"
      temp = (building ? `${building}, ` : '') + area;
    }else{
      temp = building;
    }
    return temp;
  }
  getFinalLocaton(assetObject: Asset, properties : AssetBasicInformationProperties) {
    let finalLocation = '';
    if(assetObject?.monitoredStatus?.toLowerCase() === 'monitored'){
      if((properties?.locationName || properties?.zoneName) &&
      (properties?.locationName?.toLowerCase() !== 'out of coverage' &&
       properties?.zoneName?.toLowerCase() !== 'out of coverage')) {
          if(properties?.locationName){
            finalLocation = properties?.locationName + ', ' + properties?.zoneName;
          } else {
            finalLocation = properties?.zoneName;
          }
        }
      else if(assetObject?.deviceType?.toLowerCase().includes('tracker') && assetObject?.sensorId && !_.isEmpty(assetObject?.position)) {
        let pos = assetObject?.position;
        if(assetObject?.shipment){
          finalLocation = 'Lat: '+ pos.lat + ', Lon: ' + pos.lon;
        } else if(!assetObject?.shipment && assetObject?.lastLocation) {
          finalLocation = 'Out of Coverage' + ' (at Lat: ' + pos.lat + ', Lon: ' + pos.lon + ')';
        } else {
          finalLocation = properties?.zoneName;
        }
      } else {
        finalLocation = properties?.zoneName;
      }
    } else {
      finalLocation = properties?.zoneName;
    }
    return finalLocation;
  }

  private getAssetObject(assetResponse: AssetBasicInformation, sensorType?: string, taggedAssetCharacteristicValues?: CharacteristicValue[], sensorTypeResponse?: SensorType, sensorMeasures?: MeasureType[], cdmFields?: CdmField[], shipment?: Shipment): Asset {
    let assetObject: Asset = {
      id: assetResponse.id,
      externalId: assetResponse.properties.externalId,
      name: assetResponse.properties.name,
      monitoredStatus: AssetStatus[assetResponse?.properties?.monitoredStatus.toUpperCase()],
      categoryId: assetResponse.properties.typeId,
      categoryName: assetResponse.properties.type,
      taggedAssetId: assetResponse.properties.taggedAssetId,
      sensorId: assetResponse.properties.sensorId,
      sensorIds: assetResponse.properties.sensorIds,
      sensorType: sensorType ? sensorType : '',
      zoneName: assetResponse?.properties?.zoneName,
      // location: this.getAssetCurrentLocation(assetResponse?.properties),
      lastLocation: this.getAssetLastLocation(assetResponse?.properties),
      lastSeen: assetResponse?.properties?.lastSeen ? new Date(assetResponse.properties.lastSeen) : null,
      lastReportedGPSTime: assetResponse?.properties?.lastReportedGPSTime ? new Date(assetResponse.properties.lastReportedGPSTime) : '',
      measures: (!_.isEmpty(sensorMeasures) ? sensorMeasures : (!_.isEmpty(sensorTypeResponse) ? this.sensorUtilService.getAssetMeasureTypes(sensorTypeResponse) : [])),
      state: assetResponse?.properties?.state ? assetResponse.properties.state : null,
      qrCode: assetResponse?.properties?.qrCode ? assetResponse.properties.qrCode : null,
      sku: assetResponse?.properties?.sku ? assetResponse.properties.sku : null,
      cost: assetResponse?.properties?.cost ? assetResponse.properties.cost : null,
      condition: assetResponse?.properties?.condition ? assetResponse.properties.condition : null,
      fromParty: assetResponse?.properties?.fromParty ? assetResponse.properties.fromParty : null,
      toParty: assetResponse?.properties?.toParty ? assetResponse.properties.toParty : null,
      checkedoutTo: assetResponse?.properties?.checkedoutTo ? assetResponse.properties.checkedoutTo : null,
      checkedoutToUserId: assetResponse?.properties?.checkedoutToUserId ? assetResponse.properties.checkedoutToUserId : null,
      checkedoutAt: assetResponse?.properties?.checkedoutAt ? assetResponse.properties.checkedoutAt : null,
      manufacturer: assetResponse?.properties?.manufacturer ? assetResponse.properties.manufacturer : null,
      warehouseLocation: assetResponse?.properties?.warehouseLocation ? assetResponse.properties.warehouseLocation : null,
      maintenanceDate: assetResponse?.properties?.maintenanceDate ? assetResponse.properties.maintenanceDate : null,
      modifiedAt: assetResponse?.properties?.modifiedAt ? assetResponse.properties.modifiedAt : null,
      modifiedBy: assetResponse?.properties?.modifiedBy ? assetResponse.properties.modifiedBy : null,
      lifecycleStatus: assetResponse?.properties?.lifecycleStatus ? assetResponse.properties.lifecycleStatus : null,
      createdAt: assetResponse?.properties?.createdAt ? assetResponse.properties.createdAt : null,
      createdBy: assetResponse?.properties?.createdBy ? assetResponse.properties.createdBy : null,
      organization: assetResponse?.properties?.organization ? assetResponse.properties.organization : null,
      itemCategory: assetResponse?.properties?.itemCategory ? assetResponse.properties.itemCategory : null,
      checkedoutBy: assetResponse?.properties?.checkedoutBy ? assetResponse.properties.checkedoutBy : null,
      custodyAuditCompletedAt: assetResponse?.properties?.custodyAuditCompletedAt ? assetResponse.properties.custodyAuditCompletedAt : null,
      custodyOperationAt: assetResponse?.properties?.custodyOperationAt ? assetResponse.properties.custodyOperationAt : null,
      assetStatus: assetResponse?.properties?.assetStatus ? assetResponse.properties.assetStatus : null,
      lastCustodyAuditAt: assetResponse?.properties?.lastCustodyAuditAt ? assetResponse.properties.lastCustodyAuditAt : null,
      custodyAuditId: assetResponse?.properties?.custodyAuditId ? assetResponse.properties.custodyAuditId : null,
      custodyAuditStatus: assetResponse?.properties?.custodyAuditStatus ? assetResponse.properties.custodyAuditStatus : null,
      custodyAuditBy: assetResponse?.properties?.custodyAuditBy ? assetResponse.properties.custodyAuditBy : null,
      custodyAuditInitiatedAt: assetResponse?.properties?.custodyAuditInitiatedAt ? assetResponse.properties.custodyAuditInitiatedAt : null,
      lastCustodyAuditBy: assetResponse?.properties?.lastCustodyAuditBy ? assetResponse.properties.lastCustodyAuditBy : null,
      status: assetResponse?.properties?.status ? assetResponse.properties.status : null,
      qrBarCode: assetResponse?.properties?.qrBarCode ? assetResponse.properties.qrBarCode : null,
      lastCustodyStatusUpdateAt: assetResponse?.properties?.lastCustodyStatusUpdateAt ? assetResponse.properties.lastCustodyStatusUpdateAt : null,
      classType: assetResponse?.properties?.classType ? assetResponse.properties.classType : null,
      custodyStatus: assetResponse?.properties?.custodyStatus ? assetResponse.properties.custodyStatus : null,
      properties: assetResponse.properties,
      cdmFields: cdmFields,
      deviceType: sensorTypeResponse?.type,
      imageURL: assetResponse?.properties?.imageURL,
      shipment: shipment
    };

    if(assetResponse?.properties?.boundAt){
      try{
        let temp = assetResponse.properties.boundAt;
        assetObject.sensorBindedDate = new Date(temp);
      }catch(e){}
    }
    if(assetResponse?.properties?.position){
      let tempPositions = assetResponse.properties.position.split(',');
      assetObject.position = {lat: parseFloat(tempPositions[0]), lon: parseFloat(tempPositions[1])};
    }
    if(!_.isEmpty(assetObject.measures) && !_.isEmpty(taggedAssetCharacteristicValues)){
      assetObject.measures = this.getTaggedAssetMeasureValues(assetObject.measures, taggedAssetCharacteristicValues);
    }

    assetObject.location = this.getFinalLocaton(assetObject, assetResponse?.properties);

    return assetObject;
  }
  private getTaggedAssetMeasureValues(measures: MeasureType[], values: CharacteristicValue[]) : MeasureType[]{
    measures.forEach((measure: MeasureType) => {
      let tempSidCid = measure.measureServiceId + ':' + measure.measureCharacteristicId;
      let value: CharacteristicValue = _.find(values, {'cId' : measure.measureCharacteristicId, 'sId' : measure.measureServiceId});
      if(!_.isEmpty(value) && !_.isEmpty(value.data) && !_.isEmpty(value.data[0])){
        if(!_.isUndefined(value.data[0].value) && !_.isNull(value.data[0].value)){
          measure.value = value.data[0].value;
          /* Dispay door values should be Close OR Open depends on the "measure.value" boolen
             values will be "Open" if measure.value = true; && "Closed" if measure.value = false;
          */
          if(_.includes(['Door:doorStatus'], tempSidCid)){
            measure.value = (measure.value === 'false' || measure.value === false) ? 'closed' : 'open';
          }
        }
        if(value.data[0].time){
          measure.timeOfCapture =  new Date(value.data[0].time);
        }
      }

    })
    return measures;
  }
}
