export enum WayPointStatus {
  Completed = 'complete',
  Current = 'current',
  Pending = 'pending',
}

export interface ShipmentTrackPoint {
  title: string;
  message: string;
  status: WayPointStatus;
  tooltip?: { heading: string; message: string }[];
  localeTime?: string;
  checkpointTime?: number;
  isHTMLMessage?: boolean;
}

export enum MILESTONE_ZONES {
  LOCALE = 'local',
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  USER = 'user',
}
