import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ShareService } from 'projects/clf-share/src/share.service';

@Component({
  selector: 'cl-share-shipment-details-summary',
  templateUrl: './share-shipment-details-summary.component.html',
  styleUrls: ['./share-shipment-details-summary.component.scss'],
})
export class ShareShipmentDetailsSummaryComponent implements OnInit {
  @Input() shipmentData: any;
  @Output() refreshShipment = new EventEmitter<void>();
  @Input() winkedAssetId: string;
  @Input() isRestricted:boolean;

  selectedAssetIndex: string;
  public toggle: boolean = false;

  currentAction: { isDisable: boolean; action: 'complete' | 'terminate' } = {
    isDisable: true,
    action: 'complete',
  };

  currentTab: 'progress' | 'collaborate' = 'progress';
  collaborateURI: SafeResourceUrl = '';

  actionBtnPolicies = {
    terminate: ['SHIPMENT_TERMINATE'],
    share: ['SHIPMENT_SHARE'],
    update: ['SHIPMENT_UPDATE'],
  };

  constructor() {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  // HANDLERS FOR SHARE
  public fnToggle() {
    this.toggle = !this.toggle;
  }

  // This method will update later
  public getData(data: any) {}

  public onClose(close: boolean) {
    if (close && this.toggle) {
      this.toggle = false;
    } else {
      this.toggle = true;
    }
  }
}
