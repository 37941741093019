<div class="py-2 font-bold fs24">{{shipmentData?.shipmentNode?.properties?.name}}</div>
<div class="grid grid-cols-2 gap-3">
  <div class="border shadow-sm wt-ngx-grid-wrapper h-full rounded-md">
    <div class="p-2">
      <div class="h-80 pr-1 overflow-auto">
        <cl-share-summary-data
          [selectedAssetIndex]="selectedAssetIndex"
          [shipmentData]="shipmentData"
          [isRestricted]="isRestricted"
        ></cl-share-summary-data>
      </div>
    </div>
  </div>
  <div class="border flex flex-col shadow-sm wt-ngx-grid-wrapper h-full rounded-md">
    <div
      class="cl-secondary-header text-center pt5 flex p-2 gap-4"
      style="background-color: transparent !important"
    >
      <div class="font-bold">Activity</div>
      <div class="btn-group" role="group">
        <button
          type="button"
          class="btn btn-primary p-1 cl-menu-item"
          [ngClass]="
            currentTab === 'progress' ? 'bgcolor4 color2' : 'bgcolor9 color4'
          "
          (click)="currentTab = 'progress'"
        >
          <span>Progress</span>
        </button>
        <button
        *ngIf="false"
          type="button"
          class="btn btn-primary p-1 cl-menu-item"
          (click)="currentTab = 'collaborate'"
          [ngClass]="
            currentTab === 'collaborate' ? 'bgcolor4 color2' : 'bgcolor9 color4'
          "
        >
          <span>Collaborate</span>
        </button>
        <!-- <button type="button" class="btn btn-primary color4 bgcolor2 rt1 cl-menu-item"
          *ngFor="let item of plainMenuItems; let i = index;"
          [ngClass]="{'bgcolor4 color2': activeGroup === item.label, 'bgcolor2 color4': activeGroup !==item.label, 'rounded-end': i=== plainMenuItems.length-1, 'color37 cursor-default': item.disabled}"
          [textContent]="item.label" (click)="navigateMenu(item)">
        </button>
         -->
      </div>
    </div>
    <div class="h-48 pb-2 grow" *ngIf="currentTab === 'progress'">
      <cl-share-route-plan
        [winkedAssetId]="winkedAssetId"
        [shipmentDetails]="shipmentData"
        *ngIf="shipmentData.shipmentNode.properties?.parentEntityTypeKey === 'standardshipment'"
      ></cl-share-route-plan>
      <cl-share-tracking-progress
        [winkedAssetId]="winkedAssetId"
        (selectedAssetIndex)="(selectedAssetIndex)"
        [shipmentDetails]="shipmentData"
        [isRestricted]="isRestricted"
        *ngIf="['parcelshipment', 'dtpshipment'].includes(shipmentData?.shipmentNode?.properties?.parentEntityTypeKey)"
      ></cl-share-tracking-progress>
    </div>

    <div class="p-2" style="height: calc(100% - 40px)" *ngIf="currentTab === 'collaborate' && collaborateURI !== '' && false">
      <iframe
        width="100%"
        height="100%"
        [src]='collaborateURI'
        seamless
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
