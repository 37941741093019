import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  localStoreObj: any;
  loggedInUser: any;
  constructor(
  ) {
    let storeObj = localStorage.getItem('columnsStore');
    this.localStoreObj = storeObj ? JSON.parse(storeObj) : {};
   }

   setGridColumns(page: string, columns: string[]) {
    this.loggedInUser ="lnagalla";
    if (this.localStoreObj.hasOwnProperty(this.loggedInUser)) {
        this.localStoreObj[this.loggedInUser.id][page] = [...columns];
    } else {
      this.localStoreObj[this.loggedInUser] = {};
      this.localStoreObj[this.loggedInUser][page] = columns;
    }
    localStorage.setItem('columnsStore', JSON.stringify(this.localStoreObj));
   }

   getGridColumns(page) {
    let storeObj = this.localStoreObj;
    this.loggedInUser = "lnagalla";
    if (storeObj.hasOwnProperty(this.loggedInUser) && storeObj[this.loggedInUser].hasOwnProperty(page)) {
      return storeObj[this.loggedInUser][page];
    } else {
      return {};
    }
   }
}
