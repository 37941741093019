import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { FormateDatePipe } from 'projects/clf-share/src/pipes/formate-date.pipe';
import { DynamicFormService } from 'projects/clf-share/src/services/dynamic-form.service';
import { PropertyService } from 'projects/clf-share/src/services/property.service';
import { ShipmentUtilsService } from 'projects/clf-share/src/services/shipment-utils.service';
import { UtilsService } from 'projects/clf-share/src/services/utils.service';
import { ShareService } from 'projects/clf-share/src/share.service';

@Component({
  selector: 'cl-share-summary-data',
  templateUrl: './share-summary-data.component.html',
  styleUrls: ['./share-summary-data.component.scss'],
})
export class ShareSummaryDataComponent implements OnInit {
  @Input() shipmentData: any;
  @Input() isShowAllFields: boolean = true;
  @Input() selectedAssetIndex: string;
  @Input() isRestricted:boolean;

  summaryData: { label: string; value: string; link?: string }[] = [];

  depatureTime: string;
  arrivalTime: string;
  dateDisplayFormat:any;
  depatureDiff: string;
  arrivalDiff: string;

  originAddress:string = '';
  destinationAddress:string = '';

  notes = {
    note: '',
    notesModifiedAt: null,
    notesModifiedBy: '',
  };

  isDTPShipment = true;
  constructor(
    private _shipmentUtils: ShipmentUtilsService,
    private _shipmentService: ShareService,
    private pService: PropertyService,
    private _dynamicFormService: DynamicFormService,
    public datePipe : FormateDatePipe,
    private utilService: UtilsService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    // Initinalized data in ngOnChnages because in summary component data needs to update while @input changes
    if(!this.dateDisplayFormat){
      this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
      this.dateDisplayFormat= this.dateDisplayFormat.replace('dd', 'DD'); // dd is refered as day(mon ,tue ,wed ...) in moment so replaced with DD to get (1,2,3....31)
      this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');  // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
    }
    const shipment = this.shipmentData.shipmentNode;
    const shipmentProps = this.shipmentData.shipmentNode.properties;
    const route = this.shipmentData.routeNode?.properties;

    this.isDTPShipment = this.shipmentData.shipmentNode.properties.parentEntityTypeKey == 'dtpshipment';
    this.setOriginDestination();

    this.summaryData = [
      {
        label: 'Planned Departure',
        value: this.convertDateFormat(+shipmentProps?.plannedDeparture),
      },
      {
        label: 'Planned Arrival',
        value: this.convertDateFormat(+shipmentProps?.plannedArrival),
      },
      {
        label: 'Type',
        value:
          shipment.type === 'standardshipment'
            ? 'Standard Shipment'
            : shipment.type === 'parcelshipment'
            ? 'Parcel Shipment'
            : '',
      },
      {
        label: 'Name',
        value: shipmentProps?.name,
      },
      {
        label: 'External ID',
        value: shipmentProps?.externalId,
      },
      {
        label: 'Order Number',
        value: shipmentProps?.orderNum,
      },
      {
        label: 'Shipper Name',
        value: shipmentProps?.shipperName,
      },
      {
        label: 'Carrier Name',
        value: shipmentProps?.carrierName,
      },
      {
        label: 'Customer Name',
        value: shipmentProps?.custName,
      },
      {
        label: 'Customer Code',
        value: shipmentProps?.custCode,
      },
      {
        label: 'Organization',
        value: shipmentProps?.organizationName,
      },
      {
        label: 'Mode of Transport',
        value: shipmentProps?.modeOfTransport,
      },
      {
        label: 'Shipment Direction',
        value: shipmentProps?.direction,
      },
      {
        label: 'Customer Order Ref',
        value: shipmentProps?.orderNum,
      },
      {
        label: 'Recipient Name',
        value: shipmentProps?.recipientName,
      },
      {
        label: 'Direct to Residence',
        value:
          shipmentProps?.directToResidence === 'true' ||
          shipmentProps?.directToResidence === true
            ? 'Yes'
            : 'No',
      },
      {
        label: 'Route',
        value: shipmentProps.routeHidden ? shipmentProps?.dynamicRouteLabel : shipmentProps?.routeName,
      },
      {
        label: 'Rule Set',
        value: shipmentProps?.ruleSetName,
      },
      {
        label: 'Sensor Profile',
        value: shipmentProps?.sensorProfile,
      },
      {
        label: 'Origin',
        value: shipmentProps.routeHidden ? shipmentProps?.originAreaName : route?.source,
      },
      {
        label: 'Destination',
        value: shipmentProps.routeHidden ? shipmentProps?.destinationAreaName : route?.destination,
      },
      {
        label: 'Number of Assets',
        value: this.shipmentData?.assetNodes?.length,
      },
      {
        label: 'Master AWB',
        value: shipmentProps?.mawb,
      },
      // {
      //   label: 'Tags',
      //   value: !this.isRestricted?shipmentProps?.tags?.map((e) => e).join(', '):'',
      // },
      {
        label: 'Created At',
        value: this.convertDateFormat(+shipmentProps?.createdAt),
      },
      {
        label: 'Created By',
        value: shipmentProps?.createdBy,
      },
      {
        label: 'Modified At',
        value: this.convertDateFormat(+shipmentProps?.modifiedAt),
      },
      {
        label: 'Modified By',
        value: shipmentProps?.modifiedBy,
      },
    ];

    if (shipmentProps?.notes.trim() !== '') {
      this.notes = {
        note: shipmentProps?.notes,
        notesModifiedAt: this.convertDateFormat(
          +shipmentProps?.notesModifiedAt
        ),
        notesModifiedBy: shipmentProps?.notesModifiedBy,
      };
    }

    if (shipment.type === 'parcelshipment' && !this.isRestricted) {
      const trackingIDs: string[] = shipmentProps?.assets.map(
        (asset) => asset?.trackingNumber
      );

      // Push tracking id and proof of deliveries before created At
      this.summaryData.splice(this.summaryData.length - 4, 0, {
        label: 'Tracking IDs',
        value: trackingIDs.join(','),
      });

      this.getAssetMileStones();
    }
    this.depatureDiff = null;
    this.arrivalDiff = null;
    if (shipmentProps?.actualDepTime) {
      this.depatureTime = this.convertDateFormat(+shipmentProps.actualDepTime);

      const depatureDiff = this.getDiffTimes(
        +shipmentProps.plannedDeparture,
        +shipmentProps.actualDepTime
      );

      if (depatureDiff) {
        this.depatureDiff = this.momentHumanize(depatureDiff, 'minutes');
        let d = depatureDiff.toString();
        if (d[0] == '-') {
          this.depatureDiff = '+' + this.depatureDiff;
        } else {
          this.depatureDiff = '-' + this.depatureDiff;
        }
        // this.depatureDiff =
        //   (depatureDiff > -10 && depatureDiff < 10 ? '0' : '') +
        //   Math.abs(depatureDiff);
        // this.depatureDiff =
        //   (depatureDiff < 0 ? '-' : '+') + this.depatureDiff + 'm';
      }
    }

    const actualArrival = this.isArrival
      ? shipmentProps?.actualArrivalTime
      : shipmentProps?.eta;
    if (actualArrival) {
      this.arrivalTime = this.convertDateFormat(+actualArrival);
      const arrivalDiff = this.getDiffTimes(
        +shipmentProps.plannedArrival,
        +actualArrival
      );

      if (arrivalDiff) {
        this.arrivalDiff = this.momentHumanize(arrivalDiff, 'minutes');
        let a = arrivalDiff.toString();
        if (a[0] == '-') {
          this.arrivalDiff = '+' + this.arrivalDiff;
        } else {
          this.arrivalDiff = '-' + this.arrivalDiff;
        }
      }
    }

    const restrictedOnlyFields = [
      "Planned Departure",
      "Planned Arrival",
      "Origin",
      "Destination",
      "Number of Assets",
    ];

    const parcelOnlyFields = [
      "External ID",
      "Carrier Name",
      "Customer Order Ref",
    ]

    if(shipment.type === 'parcelshipment'){
      restrictedOnlyFields.push(...parcelOnlyFields)
    }
    if(this.isRestricted){
      this.summaryData = this.summaryData.filter(field => restrictedOnlyFields.includes(field.label))
    }
    this.getExtendedAttributes();
  }

  ngOnInit(): void {}
  async getExtendedAttributes(){
    const shipment = this.shipmentData.shipmentNode;
    const shipmentCatalog = await this._shipmentUtils.getShipmentExtendedAttributes(shipment.type);
    const fields = shipmentCatalog.cdmFields.filter(field => field.group === 'User' && field.displayable);
    const orderedFields = this._dynamicFormService.orderFields([...fields],'order');
    if(orderedFields.length > 0){
      orderedFields.forEach(field=>{
       let arryField = {
            label: field.displayLabel,
            value: this.checkValue(field.type ,shipment.properties[field.id]),
          }
       this.summaryData.splice(this.summaryData.length - 4, 0, arryField);
      })
    }
  }
  checkValue(type:any, value:any){
    if(value === undefined) return value;
    else if(type.toLowerCase()==='date') {
      return this.datePipe.transform(value, 'default');
    }
    else if(type.toLowerCase()==='boolean') {
        if(value.toString().toLowerCase() =="true") return "Yes";
        if(value.toString().toLowerCase() =="false") return 'No';
    }else return value;
  }
  setOriginDestination(){

    this.originAddress = '';
    this.destinationAddress = '';

    const totalRoutes = this.shipmentData.routeNode.properties.locationDetails.length;
    if(this.shipmentData.routeNode.properties.locationDetails && typeof this.shipmentData.routeNode.properties.locationDetails == 'string'){
      this.shipmentData.routeNode.properties.locationDetails = JSON.parse(this.shipmentData.routeNode?.properties?.locationDetails)
    }
    this.shipmentData.routeNode.properties.locationDetails.forEach((route) => {
      const address = JSON.parse(
        route?.routeGeojson?.replace(/\\+/g, '')
      )?.address;

      if(route.loc_seq_order_no == 1){ //Origin

        // FIXME: This is only a quick fix
        let addArr =  address.split(',');
        addArr = addArr.slice(-2)
        this.originAddress = addArr.join();
      }

      if(route.loc_seq_order_no == totalRoutes){ //Destination

        // FIXME: This is only a quick fix
        let addArr =  address.split(',');
        addArr = addArr.slice(-2)
        this.destinationAddress = addArr.join();
      }
    });
  }
  get isArrival(): boolean {
    const { statusCode, subStatusCode } =
      this.shipmentData?.shipmentNode?.properties;

    if (!statusCode) return false;

    if (statusCode === 'At Destination') return true;

    if (statusCode === 'Completed') {
      return subStatusCode === 'Completed';
    }

    return false;
  }

  async getAssetMileStones() {
    const shipmentDateRange = this._shipmentUtils.getShipmentDateRange({
      ...this.shipmentData.shipmentNode.properties,
    });

    let asset;
    if (this.selectedAssetIndex) {
      asset = this.shipmentData.assetNodes.find(
        (asset) => asset.id === this.selectedAssetIndex
      );
    } else {
      asset = this.shipmentData.assetNodes[0];
    }

    if (!asset) return this.generateProofOfDelivery('');

    const [assetMileStones] = await this._shipmentService.getAssetMileStones({
      assetID: asset.properties.taggedAssetId,
      from: shipmentDateRange.dateRange.startDate,
      to: shipmentDateRange.dateRange.endDate,
    });

    if (!assetMileStones || !assetMileStones.data)
      return this.generateProofOfDelivery('');

    let podInfo = '';
    let isHavingPod = false;
    let isPodGenerated = false;
    assetMileStones.data.forEach((milestone) => {
      const milestoneJson = JSON.parse(milestone.value);
      if (milestoneJson.tag === 'Delivered' && milestoneJson.signed_by && !isPodGenerated) {
        const timezone = milestoneJson.checkpointLocalTime ? this.utilService.getTimezone(milestoneJson.checkpointLocalTime) : null;
        const checkpointTime = milestoneJson.checkpointLocalTime ? this.datePipe.transform(milestoneJson.checkpointLocalTime, 'default') + (timezone ? ' ('+timezone+')' : "") : ''
        
        isPodGenerated = true;
        podInfo = (this.isDTPShipment && this.isRestricted)
          ? checkpointTime
          : `Signed by: ${milestoneJson.signed_by} [ ${checkpointTime} ]`;
        this.generateProofOfDelivery(podInfo);
        isHavingPod = true;
      }
    });

    if (!isHavingPod) return this.generateProofOfDelivery('');
  }

  generateProofOfDelivery(podInfo: string) {
    this.summaryData.splice(this.summaryData.length - 4, 0, {
      label: 'Proof of Delivery Info',
      value: podInfo,
    });
  }

  convertDateFormat(planned: any) {
    if(!planned) return ' ';
    return moment(planned).format(this.dateDisplayFormat);
  }

  getDiffTimes(planned: any, actual: any) {
    if (!planned || !actual) return null;
    return Math.round((planned - actual) / (60 * 1000));
  }
  momentHumanize(event, unit) {
    let sting = event.toString();
    let min = event;
    if (sting[0] == '-') {
      min = event.toString().substring(1);
    }
    var eventMDuration = moment.duration(+min, unit);
    var eventDurationArray = [];
    if (
      eventMDuration.years() > 0 ||
      eventMDuration.months() > 0 ||
      eventMDuration.weeks() ||
      eventMDuration.days() > 0
    ) {
      eventDurationArray.push(
        eventMDuration.days() +
          eventMDuration.years() * 365 +
          eventMDuration.months() * 30 +
          eventMDuration.weeks() * 7 +
          ' d'
      );
      eventMDuration.subtract(eventMDuration.days(), 'days');
    }
    if (eventMDuration.hours() > 0) {
      eventDurationArray.push(eventMDuration.hours() + ' hrs');
      eventMDuration.subtract(eventMDuration.hours(), 'hours');
    }
    if (eventMDuration.minutes() > 0) {
      eventDurationArray.push(eventMDuration.minutes() + ' mins');
    }
    return eventDurationArray.length === 1
      ? eventDurationArray[0]
      : eventDurationArray.join(', ');
  }
}
