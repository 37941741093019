import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { UtilsService } from 'projects/clf-share/src/services/utils.service';
import moment from 'moment';
import { AlertIcon, MeasureType, WebUIParsedConfig } from 'projects/clf-share/src/types/measure.type';
import { FormateDatePipe } from 'projects/clf-share/src/pipes/formate-date.pipe';


@Component({
  selector: 'cl-share-characteristic-list',
  templateUrl: './share-characteristic-list.component.html',
  styleUrls: ['./share-characteristic-list.component.scss'],
})
export class ShareCharacteristicListComponent implements OnChanges {
  @Input('measures') measures: MeasureType[];
  @Input('data') data: ApexAxisChartSeries | any;
  @Output('change') measureUpdated: EventEmitter<MeasureType>;
  @Input('tooltip') tooltipCustionHandler: Function;
  @Input('annotations') annotations: any;
  @Input() dateFormat ;
  options: WebUIParsedConfig = { dateRanges: [], displayType: '', groupBy: '', is3Axes: false, isBinary: false, isConditionDataPlot: false, order: 0, rangedDefault: '', showIn: '', title: '', unitsfx: ''};
  activeMeasure: MeasureType;
  isMeasuresNotEmptyWithValue: boolean = false;
  constructor( public _utils: UtilsService,private datePipe:FormateDatePipe) {
    this.measureUpdated = new EventEmitter<MeasureType>();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('measures') && !_.isEmpty(this.measures)){
      this.measures = _.filter(this.measures, function(measure) {
        return measure.config['displayType'] ===  'graph';
    });
    this.changeMeasure(this.measures[0]);
    }
    if(!this.dateFormat){
      this.dateFormat ='MM/DD/YYYY hh:mm:ss A';
    }
  }
  changeMeasure(measure: MeasureType){
    this.activeMeasure = measure;
    this.setCustomChartOptions();
    this.measureUpdated.emit(measure);
    this.verifyMeasuresEmpty();
  }
  private setCustomChartOptions(): void{
    this.options = this.activeMeasure.config;
  }
  private verifyMeasuresEmpty() {
    let list: MeasureType[] = _.filter(this.measures, (item) => {
      return !_.isUndefined(item.value) && !_.isNull(item.value);
    });
    this.isMeasuresNotEmptyWithValue = !_.isEmpty(list);
  }
  convertDateFormat(planned: any) {
    return planned ? this.datePipe.transform(planned,this.dateFormat) : '';
  }
  formatAlertObject(measure: any): AlertIcon {
    let alertIconObject: AlertIcon = {
      iconColor: measure.voilated ? '#FF2500' : '#2879DE',
      measure:  measure.measureServiceId != 'PredictiveMetric'  ? measure.measureLabel : measure.cId,
      width: 24,
      height: 24
    }
    return alertIconObject;
  }
}
