import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@cl/common/services/toast.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {
  SelectionType,
  SortType,
  DatatableComponent,
} from '@swimlane/ngx-datatable';
import moment from 'moment';
import _ from 'lodash';
import { AssetUtilService } from 'projects/clf-share/src/services/asset-util.service';
import { PropertyService } from 'projects/clf-share/src/services/property.service';
import { SensorService } from 'projects/clf-share/src/services/sensor.service';
import { ShipmentUtilsService } from 'projects/clf-share/src/services/shipment-utils.service';
import { UtilsService } from 'projects/clf-share/src/services/utils.service';
import { ShareService } from 'projects/clf-share/src/share.service';
import { Asset } from 'projects/clf-share/src/types/asset.type';
import { AlertIcon } from 'projects/clf-share/src/types/measure.type';

import { Subscription } from 'rxjs';
import { FormateDatePipe } from 'projects/clf-share/src/pipes/formate-date.pipe';

@Component({
  selector: 'cl-share-included-assets',
  templateUrl: './share-included-assets.component.html',
  styleUrls: ['./share-included-assets.component.scss'],
})
export class ShareIncludedAssetsComponent implements OnInit {
  @Input() shipmentData: any;
  @Input() isRestricted: boolean;
  @Output() sendDataToapply = new EventEmitter<any>();
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('assetNameTemplate',{ static: true }) assetNameTemplate: TemplateRef<any>;
  @ViewChild('assetBatteryTemplate',{ static: true }) assetBatteryTemplate: TemplateRef<any>;
  @ViewChild('assetLastUpdatedTemplate',{ static: true }) assetLastUpdatedTemplate: TemplateRef<any>;
  @ViewChild('residualTime',{ static: true }) residualTime: TemplateRef<any>;
  @ViewChild('etaArrivalProp',{ static: true }) etaArrivalProp: TemplateRef<any>;
  @ViewChild('statusCumSubStatus',{ static: true }) statusCumSubStatus: TemplateRef<any>;

  assetNodeDetails: any;
  gridview: boolean = true;
  listview: boolean = false;
  readonly headerHeight = 25;
  readonly rowHeight = 30;
  DetailRowHeight = 210;
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  public sortType: SortType = SortType.multi;
  public selection: SelectionType = SelectionType.checkbox;
  IncludeAssets = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  isFiltersOpen: boolean;
  expanded: any = {};
  isEditBtnDisabled: boolean;
  statuspop: boolean = true;
  status: boolean = false;
  status2:boolean =false;
  timeMarkAsResolvedPop:any;
  bindAction = 'Unbind';
  terminateAction = 'Complete';
  bindIcon = 'fa-unlink';
  terminateIcon = 'fa fa-flag-checkered';
  terminateDisabled = true;
  intransitDisabled = true;
  isMoveToIntransitShown = false;
  subscriptions: Subscription[] = [];
  selectedAsset: any;
  multiSelectedAssets: any[] = [];
  bindDisabled = true;
  isBindEnabled = true;
  unBindDisabled = true;
  rightarrow = 'fa fa-chevron-right float-right';
  shipmentId: any;
  
  shipmentType: any;
  shipmentStatus: any;
  tenantTempType: any;
  deleteDisabled: boolean = false;
  showBtn: boolean = false;
  showBtnIntransit: boolean = false;
  conditionalDeviceTypes = ['logmore', 'onset', 'sensitech', 'elitech'];
  actionBtnPolicies = {
    bind: ['SHIPMENT_UPDATE', 'TAGGEDASSET_CREATE', 'ASSET_UPDATE'],
    unbind: ['SHIPMENT_UPDATE', 'TAGGEDASSET_DELETE', 'ASSET_DELETE'],
    terminate: ['ASSET_UPDATE'],
    complete: ['ASSET_UPDATE'],
    delete: ['ASSET_UPDATE'],
    intransit: ['ASSET_UPDATE'],
  };
  hideTemp: boolean = true;
  etaActual: any = 'ETA/Actual Arrival';
  assetDetails: any;
  currentMarkAsResolvedPop = '';
  ExcurstionsDetails: any;
  timeDelayDetails: any;
  sidePanelsObj: any = {
    bind: false,
  };
  degrees: any = '°';
  md: any;
  nodes: any[] = [];
  activeEntity: any;
  dateDisplayFormat: any;
  thresholdData: any;
  servicesToShow: any = [];
  multipleActions:any = [];
  singleExcersion:boolean=false;
  sensorPropsForTable:any[] = [];

  constructor(
    private shipment_service: ShareService,
    private _dialog: MatDialog,
    private pService: PropertyService,
    private sensorService: SensorService,
    public toast: ToastService,
    public _utils :UtilsService,
    public assetUtilService:AssetUtilService,
    private _shipmentUtils: ShipmentUtilsService,
   ) {}
   ngOnChanges(changes: SimpleChanges) {
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('dd', 'DD'); // dd is refered as day(mon ,tue ,wed ...) in moment so replaced with DD to get (1,2,3....31)
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A'); // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
    this.tenantTempType = '°' + this.pService.getSetting('ui.temp.unit');
    this.assetNodeDetails = this.shipmentData.assetNodes;
    this.assetNodeDetails.forEach((element) => {
      element.checked = false;
    });
    this.preparingAssets();
    this.checkSensorSupport();
    this.shipmentId = this.shipmentData.shipmentNode.id;
    this.shipmentType = this.shipmentData.shipmentNode.type;
    this.shipmentStatus = this.shipmentData.shipmentNode.properties.statusCode;
    this.vilationCheck();
    this.actualArrivalStatus();
    this.dynamicComponents();
    this.resetButtons();
    // remaining trip calculation
    if(!_.isEmpty(this.assetNodeDetails)){
      this.assetNodeDetails.forEach((asset : any) => {
        if(!_.isEmpty(asset.properties.feedDetails.residualthermalcharge)){
          let tripTime = this.shipmentData.shipmentNode.properties.eta
          let _duration = tripTime - (new Date()).getTime();
          tripTime = this._utils.dwellTimeFormatter(_duration);
          asset.properties.feedDetails['remaningTripTime'] = tripTime;
        }
      });
      
    }
    this.multipleAction();
  }
  assetObject: Asset;
  ngOnInit(): void {

  }
  dynamicComponents(){    
      if(!_.isEmpty(this.assetNodeDetails)){
       this.assetNodeDetails.forEach((asset,index) => {
        this.assetUtilService.getAsset(asset.id,asset)
        .then((response: Asset) => {
             this.assetObject = response;               
             this.selectedAsset = asset;
             if (this.assetObject && this.assetObject.measures) {
              this.assetObject.measures = this.assetObject.measures.filter(measure => measure.hasOwnProperty('value'));
              let clonedMeasures = _.cloneDeep(this.assetObject.measures)
            }
             this.assetNodeDetails[index].capabilities=this.filterCapabilities(response.measures,index);         
            this.assetNodeDetails[index].measures=response.measures;
        }).catch(e=>{});
       });                     
        this.buildTable()
      }
  }
  capabilitiesData : any = [];
  buildTable(){
    this.columns = [
      {
        name: 'Asset Name',
        prop: 'properties.name',
        visible: true,
        width: 250,
        cellTemplate: this.assetNameTemplate,
        frozenLeft: true
      },
      { name: 'Status', prop: 'statusCumSubStaus', width: 100, visible: true, cellTemplate: this.statusCumSubStatus },
      {
        name: this.etaActual,
        prop: 'properties.actualArrivalTime',
        visible: true,
        width: 100,
        cellTemplate:this.etaArrivalProp
      },
      {
        name: 'Sensor Battery',
        prop: 'properties.feedDetails.battery',
        visible: true,
        width: 100,
        cellTemplate:this.assetBatteryTemplate
      }
    ];  
    if(!this.isRestricted){
      this.columns.push(
        {
        name: 'Last Location',
        prop: 'properties.feedDetails.lastResolvedLocation',
        visible: false,
        width: 100,
      },
      )
    }        
    this.sensorPropsForTable.forEach(sensor => {
      this.columns.push({ name: sensor.label, prop: 'properties.'+ sensor.id, visible: true, width: 175})
    })
      this.columns.push(
        {
          name: 'Remaining Trip Time',
          prop: 'properties.feedDetails.remaningTripTime',
          visible: true,
          width: 120,
        },{
          name: 'Last Updated On',
          prop: 'properties.updatedTime',
          visible: true,
          width: 150,
          cellTemplate:this.assetLastUpdatedTemplate
        })
    this.rawColumns = [...this.columns];
    if (this.listview) {
      this.listview = false;
      this.gridview = true;
      setTimeout(() => {
        this.listview = true;
        this.gridview = false;
      }, 1);
    }
  }
  filterCapabilities(data,index) {
    this.capabilitiesData = [];
    var measures = this.formatDependencies(data);
    measures.forEach((measure : any) => {
        if (!_.isEmpty(measure)) {
            this.capabilitiesData.push(measure);
        }
    });
    // for residualThermalTime
    this.capabilitiesData.forEach((capabilities : any) => {
      if (!_.isEmpty(capabilities) && capabilities.cId === 'residualThermalTime') {
          let intoSec = (moment.duration(capabilities.value).asSeconds())*1000;
          capabilities.value = this._utils.dwellTimeFormatter(intoSec);
      }
    });

    this.capabilitiesData.forEach(capabilitiy => {
      if(!this.sensorPropsForTable.find(sensor => sensor.id == capabilitiy.measureCharacteristicId)){
        this.sensorPropsForTable.push({
          id: capabilitiy.measureCharacteristicId,
          label: capabilitiy.measureLabel
        })
      }
      this.assetNodeDetails[index].properties[capabilitiy.measureCharacteristicId] = capabilitiy.value + '' + capabilitiy.uom
    })

    
    this.buildTable();
    if(this.capabilitiesData.length > 0){      
      this.capabilitiesData= this.violationUpdate(this.assetNodeDetails[index].allexcursions);      
      return this.capabilitiesData  
    }
    
  }
  excursionObj:any;
  violationUpdate(response){    
    this.excursionObj=response
    if (this.excursionObj?.ExcursionSummary && this.capabilitiesData.length >0) {      
      this.capabilitiesData.forEach(measure => {        
        if (
          this.excursionObj.ViolationMap[measure.measureServiceId+':'+measure.measureCharacteristicId] != undefined
        ) {
          this.excursionObj.ViolationMap[measure.measureServiceId+':'+measure.measureCharacteristicId].forEach(excursion=> {

            if(excursion.violated && (excursion.alertStatus == 'Active')) {
              measure.violated = true;
            }
          })
          }
      });
     }           
     return this.capabilitiesData
   } 
  formatDependencies(measures) {
    measures.map((measure: any) => {
      measure.dependencies = [];
        if (measure.config.dependencyMetric && !_.isEmpty(measure.measureServiceId)) {
            var dependency = this.formatDependencyString(measure.config.dependencyMetric);
            dependency.forEach(function(innerService){
                var index = _.findIndex(measures, function(originService) {
                    return innerService.split(':')[1] ===  originService['measureCharacteristicId'];
                });
                if (index > -1) {
                    measure.dependencies.push(measures[index]);
                    measures.splice(index,1);
                }
            })
        }
        return measure;
    });
    return measures;
  }
  formatDependencyString (str) {
    str = str
    .replace(/\\/g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/"/g, '');
    str = str.split(',');
    return str;
}
  multipleAction(){
    this.multipleActions =[];
    this.assetNodeDetails.forEach((element) => {
      let bind = this.getBindAction(element)
      let terminate =this.getTerminateActionData(element)
      const intransit = this.getIntransit(element);
      let actions = {
        id:element.id,
      bindDisabled : bind.bindDisabled,
      unBindDisabled:bind.unBindDisabled,
      terminateDisabled :terminate.terminateDisabled,
      terminateAction :terminate.terminateAction,
      terminateIcon :terminate.terminateIcon,
      intransitDisabled: intransit.intransitDisabled,
      isMoveToIntransitShown: intransit.isMoveToIntransitShown,
      showDeleteBtn :
      (element.properties.statusCode?.toLowerCase() == 'planned' ||
      element.properties.monitoredStatus == 'UNMONITORED') && element.properties.statusCode != 'Completed'
        ? true
        : false
     }
     this.multipleActions.push(actions)
    })
  }
  getBindAction(baseAsset) {
    let unBindDisabled=true;
    let bindDisabled =
      this.shipmentStatus !== 'Completed' &&
      this.shipmentStatus !== 'At Destination' &&
      baseAsset.properties &&
      baseAsset.properties.statusCode.toLowerCase() == 'planned'
        ? false
        : true;
    if (
      baseAsset.properties?.monitoredStatus == 'MONITORED' &&
      ['At Destination'].includes(baseAsset?.properties?.statusCode)
    ) {
      unBindDisabled = false;
    } else {
      unBindDisabled = true;
    }
    let obj = {
      unBindDisabled:unBindDisabled,
      bindDisabled:bindDisabled
    }
    return obj
  }
  getTerminateActionData(baseAsset: any) {
    let obj = {
      terminateDisabled :true,
      terminateAction :'Complete',
      terminateIcon :'fa fa-flag-checkered',
    }
    if (baseAsset?.properties?.monitoredStatus) {
      obj.terminateDisabled = false;
      if (baseAsset.properties.monitoredStatus == 'MONITORED') {
        if (
          ['Ready to Ship', 'In-Transit'].includes(
            baseAsset?.properties?.statusCode
          )
        ) {
          obj.terminateAction = 'Terminate';
          obj.terminateIcon = 'fa-ban';
          obj.terminateDisabled = false;
        } else if (baseAsset?.properties?.statusCode == 'At Destination') {
          obj.terminateDisabled = false;
          obj.terminateAction = 'Complete';
          obj.terminateIcon = 'fa fa-flag-checkered';
        } else if (baseAsset?.properties?.statusCode == 'Planned') {
          obj.terminateDisabled = true;
          obj.terminateAction = 'Complete';
          obj.terminateIcon = 'fa fa-flag-checkered';
        }
      } else if (baseAsset.properties.monitoredStatus == 'UNMONITORED') {
        obj.terminateAction = 'Complete';
        obj.terminateIcon = 'fa fa-flag-checkered';
        obj.terminateDisabled = true;
      }
    }
    return obj
  }
  popoverExcursion(obj) {
    this.status2=false;
    this.timeDelayDetails = [];
    this.ExcurstionsDetails = [];
    this.ExcurstionsDetails = obj.tableViolation;
    this.ExcurstionsDetails = this.thresholdPrepare(this.ExcurstionsDetails);
    this.currentMarkAsResolvedPop = obj.id;
    this.status = !this.status;
  }
  thresholdPrepare(obj) {
    obj.forEach((element, index) => {
      obj[index].threshold =
        this.thresholdData.eventConfig[element.propertyName];
    });
    return obj;
  }
  formatAlertObjectNew(measure: any): AlertIcon {
    let alertIconObject: AlertIcon = {
      iconColor: measure.violated ? '#FF2500' : '#2879DE',
      measure:  measure.measureServiceId != 'PredictiveMetric'  ? measure.measureLabel : measure.cId,
      width: 20,
      height: 20
    }
    return alertIconObject;
  }
  popoverTimeDelayExcursion(obj) {
    if(obj.count == 0 && obj.timeDelayCount == 1)
    this.singleExcersion=true;
    this.status=false;
    this.timeDelayDetails = [];
    this.ExcurstionsDetails = [];
    var x = obj.violations['Location:originDelay'];
    if (x != undefined) this.timeDelayDetails.push(x);
    var y = obj.violations['Location:arrivalDelay'];
    if (y != undefined) this.timeDelayDetails.push(y);
    var z = obj.violations['Location:etaDelay'];
    if (z != undefined) this.timeDelayDetails.push(z);
    this.timeMarkAsResolvedPop = obj.tenantId;
    this.status2 = !this.status2;
  }
  gridFun() {
    this.listview = false;
    this.gridview = true;
  }
  listFun() {
    this.gridview = false;
    this.listview = true;
    this.rightarrow = 'fa fa-chevron-right float-right';
    this.status = false;
  }
  actualArrivalStatus() {
    this.assetNodeDetails.forEach((element) => {
      if (
        (this.shipmentStatus == 'Completed' ||
          this.shipmentStatus == 'At Destination') &&
        (element?.properties?.statusCode == 'In-Transit' ||
          element?.properties?.statusCode == 'Ready to Ship' ||
          element?.properties?.statusCode == 'Planned' ||
          element?.properties?.statusCode == 'PLANNED')
      ) {
        this.etaActual = 'ETA/Actual Arrival';
      } else if (
        this.shipmentStatus == 'Completed' ||
        this.shipmentStatus == 'At Destination'
      ) {
        this.etaActual = 'Actual Arrival';
      } else if (
        this.shipmentStatus != 'Completed' ||
        this.shipmentStatus != 'At Destination'
      ) {
        this.etaActual = 'ETA';
      }
    });
  }

  getId(row) {
    return row.shipmentId;
  }

  getUnits(val, row) {
    let units = '';
    if (
      val == 'Ambient Temperature' ||
      val == 'Cargo Temperature' ||
      val == 'Temperature'
    ) {
      units = this.tenantTempType;
    }
    if (val == 'Humidity' || val == 'Battery') {
      units = '%';
    }
    if (val == 'Light') {
      units = ' lux';
    }
    if (val == 'Pressure') {
      this.assetNodeDetails.forEach(ele => {
     if(ele.id == row.id){
      units=  " "+ele.properties.feedDetails.pressureUnits
     }
      })
     }
    if (val == 'Tilt') {
      units = ' deg';
    }
    if (val == 'Shock') {
      units = ' g';
    }
    if (val == 'Door') {
      units = '';
    }
    if (val == 'ETA Delay' || val == 'Origin Delay' || val == 'Arrival Delay') {
      units = ' ';
    }
    return units;
  }
  AssetCheck(event, index) {
    this.bindDisabled = !this.bindDisabled;
    this.terminateDisabled = !this.terminateDisabled;
  }
  onActivate() {}

  getRowClass = (row) => {
    return {
      'row-violation':
        row.count != 0 ||
        row.timeDelayCount != 0 ||
        row.excursions != undefined,
      'row-default': row.count == undefined || row.count == 0,
    };
  };

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.assetNodeDetails.forEach((element) => {
      element.checked = false;
    });
    if (selectedRows.length > 0) {
      this.assetNodeDetails.forEach((element) => {
        selectedRows.forEach((ele) => {
          if (ele.id == element.id) {
            element.checked = true;
          }
        });
      });
    }
    this.updateAction();
  }
  toggleExpandRow(row, index) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    // console.log('Detail Toggled', event);
  }
  stringTruncate(value: string, size: number = 15): string {
    if (!value) return '';

    const limit = size > 0 ? size : 15;
    return value.length > limit
      ? value.substring(0, limit).trim() + '...'
      : value;
  }
  closepopover() {
    this.status = !this.status;
  }
  gridColumnToggle(columns: any[]) {
    this.columns = columns.filter((col: any) => col.visible);
    this.table?.recalculate();
  }
  getBindActionData(event, baseAsset: any, index) {
    this.assetNodeDetails.forEach((element) => {
      if (element.id == baseAsset.id) element.checked = event.checked;
    });
    this.updateAction();
  }
  updateAction() {
    this.multipleAction();
    this.multiSelectedAssets = this.assetNodeDetails.filter((value) => {
      return value.checked;
    });
    if (this.multiSelectedAssets.length == 1) {
      this.selectedAsset = this.multiSelectedAssets[0].properties;
          this.multipleActions.forEach(action => {
             if(this.multiSelectedAssets[0].id == action.id){
              this.bindDisabled = action.bindDisabled;
              this.unBindDisabled=action.unBindDisabled;
              this.terminateDisabled =action.terminateDisabled;
              this.terminateAction =action.terminateAction;
              this.terminateIcon =action.terminateIcon;
              this.intransitDisabled=action.intransitDisabled;
              this.isMoveToIntransitShown = action.isMoveToIntransitShown;
              this.showBtn =action.showDeleteBtn;
             }
          })
    } else if(this.multiSelectedAssets.length > 1){
      this.selectedAsset = null;
      this.resetButtons();
      let terminateActionCount =0;
      let terminateIconCount =0;
      let terminateDisabledCount =0;
      let intransitDisabledCount =0;
      let isMoveToIntransitShownCount = 0;
      let showBtnCount =0;
      let unBindDisabledCount =0;
      let multiactions = this.multipleActions.filter(o1 => this.multiSelectedAssets.some(o2 => o1.id === o2.id));
       multiactions.forEach((action) => {
        this.multiSelectedAssets.forEach(assets =>{
          if(action.id==assets.id){
            if(multiactions[0].unBindDisabled == action.unBindDisabled) {unBindDisabledCount++}
            if(multiactions[0].terminateAction == action.terminateAction) {terminateActionCount++}
            if(multiactions[0].terminateIcon == action.terminateIcon) {terminateIconCount++}
            if(multiactions[0].terminateDisabled == action.terminateDisabled) {terminateDisabledCount++}
            if(multiactions[0].intransitDisabled == action.intransitDisabled) {intransitDisabledCount++}
            if(multiactions[0].isMoveToIntransitShown == action.isMoveToIntransitShown) {isMoveToIntransitShownCount++}
            if(multiactions[0].showDeleteBtn== action.showDeleteBtn) {showBtnCount++}
          }
        })
     })
     if(this.multiSelectedAssets.length == unBindDisabledCount){this.unBindDisabled = multiactions[0].unBindDisabled}
     if(this.multiSelectedAssets.length == terminateActionCount){this.terminateAction = multiactions[0].terminateAction}
    if(this.multiSelectedAssets.length == terminateIconCount){this.terminateIcon = multiactions[0].terminateIcon}
    if(this.multiSelectedAssets.length == terminateDisabledCount){this.terminateDisabled = multiactions[0].terminateDisabled}
    if(this.multiSelectedAssets.length == intransitDisabledCount){this.intransitDisabled = multiactions[0].intransitDisabled}
    if(this.multiSelectedAssets.length == isMoveToIntransitShownCount){this.isMoveToIntransitShown = multiactions[0].isMoveToIntransitShown}
    if(this.multiSelectedAssets.length == showBtnCount){this.showBtn = multiactions[0].showDeleteBtn}
    }
     else{
      this.resetButtons();
      this.selectedAsset = null;
      return;
    }
  }
  changeStatus(index, checked) {
    this.assetNodeDetails[index].checked = checked;
  }
  Excursions: any;


  closeSidePanel() {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
  }
  // formate messages




  preparingAssets() {
    var myobj = [];
    this.shipmentData.shipmentNode.properties.assets.forEach((element) => {
      var uniqueShipment = {
        trackingNumbers: '',
        assetId: '',
        assetNodeDetails: [],
      };
      uniqueShipment.trackingNumbers = element.trackingNumber;
      uniqueShipment.assetId = element.assetId;
      var x = this.assetNodeDetails.filter((e) => e.id == element.assetId);
      uniqueShipment.assetNodeDetails = x;
      myobj.push(uniqueShipment);
    });
    this.assetDetails = myobj;
  }
  ViolationMap: any;
  Excursions2:any
  vilationCheck() {
    this.assetNodeDetails.forEach((element, index) => {
      this.shipment_service
        .getShipmentExcursions(
          this.shipmentId,
          element.properties.taggedAssetId
        )
        .subscribe((data) => {
          this.Excursions = data;
          this.ViolationMap = this.Excursions.ViolationMap;
          this.Excursions2 = this.Excursions.ExcursionSummary;
          this.assetNodeDetails[index].excursions = this.Excursions2;
          this.assetNodeDetails[index].violations = this.ViolationMap;
          this.assetNodeDetails[index].allexcursions=this.Excursions
          var count = 0;
          var timeCount = 0;
          if (this.ViolationMap) {
            Object.keys(this.ViolationMap).forEach((voilatedMeasure) => {
              if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('temperature')  &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .cargotemperatureViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.cargotemperatureViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('humidity.svg')  &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .humidityViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.humidityViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('pressure.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .pressureViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.pressureViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('temp.svg')&&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .ambienttemperatureViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.ambienttemperatureViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('temperature.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .temperatureViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.temperatureViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('entry_exit.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .doorstatusViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.doorstatusViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('damaged.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .shockViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.shockViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('vibration.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .tiltViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.tiltViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('light.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .lightViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.lightViolated = true;
                    }
                    count = count + 1;
                  }
                });
              }else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('thermaltime.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    if (
                      !this.assetNodeDetails[index].properties.feedDetails
                        .residualThermalChargeViolated
                    ) {
                      this.assetNodeDetails[
                        index
                      ].properties.feedDetails.residualThermalChargeViolated = true;
                    }
                    count = count + 1;
                  }
                });
              } else if (
                this.svgSrcFinder(this.ViolationMap[voilatedMeasure][0].canonicalPropertyName?.toLowerCase()).includes('dwell.svg') &&
                this.ViolationMap[voilatedMeasure]
              ) {
                this.ViolationMap[voilatedMeasure].forEach((ele) => {
                  if (ele.violated && ele.alertStatus == 'Active') {
                    timeCount = timeCount + 1;
                  }
                });
              }
            });
          }
          this.assetNodeDetails[index].count = count;
          this.assetNodeDetails[index].timeDelayCount = timeCount;
          if(index == this.assetNodeDetails.length -1){
            this.violationsPreperation();
          }
        }, error=>{
          // checking if last api call failed
          if(index == this.assetNodeDetails.length -1){
            this.violationsPreperation();
          }
        });
    });
  }

  formatAlert(measure): AlertIcon {
    let alertIconObject: AlertIcon = {
      iconColor: '#FF2500',
      measure: measure,
      width: 14,
      height: 14,
    };
    return alertIconObject;
  }

  formatAlertTable(measure): AlertIcon {
    let alertIconObject: AlertIcon = {
      iconColor: '#FF2500',
      measure: measure,
      width: 20,
      height: 20,
    };
    return alertIconObject;
  }
  name: any;
  formatAlertSingle(obj): AlertIcon {
    obj.forEach((element) => {
      if (element.value instanceof Array) {
        element.value.forEach((element2) => {
          if (
            element2.alertStatus == 'Active' &&
            element2.violationTypeGroup == 'Conditions'
          )
          this.name = element2.canonicalPropertyName.toLowerCase();
        });
      }
    });
    let alertIconObject: AlertIcon = {
      iconColor: '#FF2500',
      measure: this.name,
      width: 20,
      height: 20,
    };
    return alertIconObject;
  }
  svgSrcFinder(type) {
    return this._shipmentUtils.getExcursionIcon(type);
  }
  formatAlertObject(measure, condition): AlertIcon {
    if (condition == true) var colour = '#FF2500';
    if (condition == false) var colour = '#2879DE';
    let alertIconObject: AlertIcon = {
      iconColor: colour,
      measure: measure,
      width: 20,
      height: 20,
    };
    return alertIconObject;
  }
  checkDecimal(value, type) {
    if (type != 'Time Delays' && !isNaN(value)) return (+value).toFixed(1);
    else {
      if (String(value) == 'true') {
        return 'Opened';
      }
      if (String(value) == 'false') {
        return 'Closed';
      } else {
        return value;
      }
    }
  }
  passAssetValue(assetId) {
    this.sendDataToapply.emit(assetId);
  }
  uniqueSens: any = [];
  checkSensorSupport() {
    this.assetNodeDetails.forEach((element, index) => {
      if (element.properties.sensorIds) {
        let uniq :String[]=[];
        let type :string[]=[];
        let sLength =element.properties.sensorIds?.length;
        element.properties.sensorIds.forEach((element2 , index) => {
          this.sensorService
            .getSensorInformation(element2)
            .then((response) => {
              const sensorType = response;
              uniq.push(sensorType.properties.type)
              type.push(sensorType.properties.vendor)
              type = [...new Set(type)];
              element.properties.vendor =type;
                if (
                !this.uniqueSens.find(
                  (o) => o === sensorType.properties.type
                ) &&
                sensorType.properties.type != undefined
              )
                this.uniqueSens.push(sensorType.properties.type);
                if(sLength-1 == index){
                  uniq = [...new Set(uniq)];
                  element.properties.sensor = uniq;
                  this.sensorService.getSensorTypeDetails(uniq.join()).then((response) => {
                    var sensorServices = response;
                    element.services = response.services;
                    this.updateTableBySensor();
                    var x = sensorServices.services.filter(
                      (e) => e.identifier == 'Pressure'
                    );
                    element.properties.feedDetails
                      ? (element.properties.feedDetails.pressureUnits =
                          x[0]?.characteristics[0]?.configParams?.unit)
                      : '';
                    });
                }

            })
            .catch((err) => {});
        });
      }
    });
  }

  updateTableBySensor() {
    if (this.uniqueSens.length == 1) {
      this.assetNodeDetails.forEach((element) => {
        element.services?.forEach((element2) => {
          element2.characteristics.forEach((element3) => {
            if (element3.identifier == '37C7BBA1-B06A-11E3-8000-B70F3AB862A4')
              this.servicesToShow.push('temp');
            if (element3.identifier == 'ambientTemp')
              this.servicesToShow.push('ambientTemp');
            if (element3.identifier == 'cargoTemp')
              this.servicesToShow.push('cargoTemp');
            if (element3.identifier == 'doorStatus')
              this.servicesToShow.push('door');
            if (element3.identifier == 'rxShockMagnitude')
              this.servicesToShow.push('shock');
            if (element3.identifier == 'rxTilt')
              this.servicesToShow.push('tilt');
            if (element3.identifier == 'rxTiltMagnitudeX')
              this.servicesToShow.push('tiltX');
            if (element3.identifier == 'rxTiltMagnitudeY')
              this.servicesToShow.push('tiltY');
            if (element3.identifier == 'rxTiltMagnitudeZ')
              this.servicesToShow.push('tiltZ');
            if (element3.identifier == 'rxPressure')
              this.servicesToShow.push('pressure');
            if (element3.identifier == 'AA21')
              this.servicesToShow.push('humidity');
            if (element3.identifier == 'rxLight')
              this.servicesToShow.push('light');
            if (element3.identifier == 'residualThermalCharge')
              this.servicesToShow.push('residualThermalCharge');
          });
        });
      });
    }
    if (this.uniqueSens.length > 1) {
      this.servicesToShow = [
        'temp',
        'cargoTemp',
        'ambientTemp',
        'door',
        'shock',
        'tilt',
        'tiltX',
        'tiltY',
        'tiltZ',
        'pressure',
        'humidity',
        'light',
        'residualThermalCharge'
      ];
    }
  }

  convertDateFormat(planned: any) {
    return planned ? moment(planned).format(this.dateDisplayFormat) : '';
  }

  violationsPreperation() {
    var violation = [];
    setTimeout(() => {
      this.assetNodeDetails.forEach((element, index) => {
        violation = [];
        if(element.violations !=undefined && element.violations !=null){
          Object.keys(element.violations).forEach((key) => {
            element.violations[key].forEach((v) => {
              violation.push(v);
            });
          });
          violation?.sort((a, b) => a.violationType.localeCompare(b.violationType));
          this.assetNodeDetails[index].tableViolation = violation;
        }
      });
    }, 500);
    if (this.shipmentData.shipmentNode.properties.ruleSet) {
      this.getRuleSetProfile(this.shipmentData.shipmentNode.properties.ruleSet);
    }
  }
  getRuleSetProfile(rulsetId: string) {
    this.shipment_service.getRuleSetProfile(rulsetId).subscribe((result) => {
      this.thresholdData = result;
    });
  }
  resetButtons() {
    this.terminateAction = 'Complete';
    this.terminateIcon = 'fa fa-flag-checkered';
    this.terminateDisabled = true;
    this.intransitDisabled = true;
    this.isMoveToIntransitShown = false;
    this.bindDisabled = true;
    this.unBindDisabled = true;
    this.showBtn = false;
  }

  getIntransit(baseAsset: any) {
    let intransitDisabled = true;
    let isMoveToIntransitShown = false;
    const assetStatus = baseAsset?.properties?.statusCode.toLowerCase();

    if (baseAsset?.properties?.monitoredStatus && baseAsset.properties.monitoredStatus == 'MONITORED') {
        baseAsset.properties?.vendor?.forEach((sens) => {
          if (this.conditionalDeviceTypes.includes(sens.toLowerCase())) {
            if (sens.toLowerCase() == 'sensitech') {
              baseAsset.properties?.sensor?.forEach((device) => {
                if (device.slice(0, 12).toLowerCase() == 'sen-tt-ultra') {
                  intransitDisabled = assetStatus != 'ready to ship';
                  isMoveToIntransitShown = true;
                }
              });
            } else {
              intransitDisabled = assetStatus != 'ready to ship';
              isMoveToIntransitShown = true;
            }
          }
        });
    }
    return {intransitDisabled,isMoveToIntransitShown};
  }
  
  residualThermalTimeConversion(tripTime : any){
    if(tripTime){
      let intoSec = (moment.duration(tripTime).asSeconds())*1000;
      return this._utils.dwellTimeFormatter(intoSec);
    } else {
      return tripTime;
    }
  }
}