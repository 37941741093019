import { Injectable } from '@angular/core';
import { TenantAssigned } from '@cl/@types/tenant-assigned.type';
import * as _ from 'lodash';
import { TenantService } from './tenant.service';
@Injectable({
  providedIn: 'root'
})
export class TenantUtilService {
  tenants: TenantAssigned[];
  firstTenantId;
  isUserFromParentTenant;
  constructor(private tenantService: TenantService) {
  }
  getTenants(userId: string){
    return new Promise<TenantAssigned[]>((resolve)=>{
      this.tenantService.getTenants(userId)
      .then((response: TenantAssigned[])=>{
        this.tenants = !_.isEmpty(response) ? response: [] ;
        this.firstTenantId = response[0]?.tenantId;
        resolve(this.tenants);
      })
      .catch(()=>{
        resolve([]);
      });
    });
  }
  retrieveTenants() {
    return this.tenants;
  }
  getFirstTenantId() {
    return this.firstTenantId;
  }
  // NOTE: For share eurl we dont want to store tenats
  // setPersistedTenant(tenantId: string){
  //   localStorage.setItem('tenantId', tenantId);
  // }
  // clearPersistedTenant(){
  //   localStorage.removeItem('tenantId');
  // }
  // getPersistedTenant(): string{
  //   return localStorage.getItem('tenantId');
  // }
}