import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShareViewComponent } from './share-view/share-view.component';
import { ShareShellComponent } from './share-shell.component';


const routes: Routes = [
  {path: ':id', component: ShareViewComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  declarations: [
    ShareShellComponent
  ]
})
export class ShareRoutingModule { }
