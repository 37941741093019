import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { Entity } from '@cl/models';
// import { UserService } from '../../user/user.service';
import * as moment from 'moment';
import { ApiProviderService } from './api-provider.service';
import { SensorType } from '../types/sensor-type.type';
import { SensorBasicInformation, SensorDeviceEvents, UnparsedSensorTaggedAssetAssignment } from '../types/sensor.type';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SensorService {

  constructor(private http : HttpClient, private apiProvider: ApiProviderService, private userService: UserService) { }
  getSensorTypeDetails(sensorTypeName: string) : Promise<SensorType>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/meta/peripheral/${sensorTypeName}?details=true`);
    return firstValueFrom(obs$);
  }
  getSensorInformation(sensorId: string): Promise<SensorBasicInformation>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor/${sensorId}`);
    return firstValueFrom(obs$);
  }
  getTaggedAssetAssignment(sensorId: string): Promise<UnparsedSensorTaggedAssetAssignment[]>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedAsset/assignment/${sensorId}`);
    return firstValueFrom(obs$);
  }
  getSensorDeviceEvents(sensorId: string) : Promise<SensorDeviceEvents>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/device/${sensorId}/tag`);
    return firstValueFrom(obs$);
  }
  getDeviceEventCharacteristicValuesByRange(sensorId: string, deviceType: string, from: number, to: number, sampleSize: number): Promise<SensorDeviceEvents>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/device/single/${sensorId}/tag/${deviceType}/heartbeat?from=${from}&to=${to}&limit=${sampleSize}`);
    return firstValueFrom(obs$);
  }
  replaceSensor(payload: any): Observable<Entity> {
    const headers = {
      "Content-Type": "application/json",
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor/replace`;
    return this.http.post<Entity>(url, payload, { headers: headers });
  }
  getRssiEvents(taggedAssetId: string, eventType: string, from: number, to: number, sampleSize: number):Promise<any>{
    const url: string = `${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${taggedAssetId}/Location/${eventType}?from=${from}&limit=${sampleSize}&to=${to}`;
    const obs$ = this.http.get(url);
    return firstValueFrom(obs$);
  }


  getWatchListStatus(id) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/sentinel100l/watchlist/${id}`);
  }

  getGPSTracker(id, from, to) {
    var userData = this.userService.getUser();
    // let timeZone = (userData && userData.timezone) ? userData.timezone : moment.tz.guess();
    let timeZone = userData && userData?.timezone;
    let  headers = {
      zoneId: timeZone
    }
    let params = {
      from: from,
      headers: JSON.stringify(headers),
      to: to
    }
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${id}/GPS_SERVICE/GeoPoint`, {params: params});
  }
}
