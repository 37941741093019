import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import _ from 'lodash';
import moment from 'moment';
import { DatatableComponent, SortType } from '@swimlane/ngx-datatable';
import { ShareService } from 'projects/clf-share/src/share.service';
import { FormateDatePipe } from 'projects/clf-share/src/pipes/formate-date.pipe';
import { CsvExportsService } from 'projects/clf-share/src/services/csv-exports.service';
import { Asset } from 'projects/clf-share/src/types/asset.type';
import { MeasureType } from 'projects/clf-share/src/types/measure.type';
import { CharacteristicGeoAreaValue } from 'projects/clf-share/src/types/characteristic-value.type';
import { AssetService } from 'projects/clf-share/src/services/asset.service';
import { AssetUtilService } from 'projects/clf-share/src/services/asset-util.service';
import { ShipmentUtilsService } from 'projects/clf-share/src/services/shipment-utils.service';

@Component({
  selector: 'cl-share-env-conditions',
  templateUrl: './share-env-conditions.component.html',
  styleUrls: ['./share-env-conditions.component.scss'],
})
export class ShareEnvConditionsComponent implements OnInit, OnChanges {
  @Input('selectedAsset') selectedAsset;
  @Input() dateFormat ;
  @Input() isRestricted: boolean;
  @Input('shipmentDetails') shipmentDetails;
  @Input('selectedDateRange') selectedDateRange: any;
  @Input('violations') violations: any;
  @ViewChild('table') table:DatatableComponent;
  @ViewChild('assetNameTemplate') assetNameTemplate: TemplateRef<any>;
  assetObject: Asset;
  lastReportedGPSTimeFormatted: any;
  data: ApexAxisChartSeries = [];
  private currentMeasure: MeasureType;
  public selectedMeasure:any = {};
  ranges: any;
  showEnv = true;
  isLoading = false;
  isMeasuresNotEmptyWithValue = false;
  // loggerData table
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public tooltipCustomizer: any;
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  public sortType: SortType = SortType.multi;
  public loggerData:any = [];
  public coordinates:any = [];
  public isMeasureValuesAvailable: boolean = false;
  public thresholdData:any;
  public minThreshold:any;
  public maxThreshold:any;
  public annotations: any;
  isDTPShipment = true;

  constructor(private formateDatePipe: FormateDatePipe,private shipmentService : ShareService, private assetService : AssetService, private assetUtilService: AssetUtilService, private changeDetectorRef: ChangeDetectorRef, private csvExportService: CsvExportsService,   private _shipmentUtils: ShipmentUtilsService,) { }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.isDTPShipment = this.shipmentDetails.shipmentNode.properties.parentEntityTypeKey == 'dtpshipment';
    this.assetObject = {id : this.selectedAsset?.id, externalId : '', name: '', status : null };
    this.getAsset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['selectedDateRange']) {
      if(!changes['selectedDateRange'].firstChange) {
        if(this.assetObject && this.assetObject.measures && this.assetObject.measures.length > 0 && this.selectedMeasure) {
          this.measureChanged(this.selectedMeasure);
        }
      }

    }
    if(changes['selectedAsset']) {
      if(changes['selectedAsset']) {
        if(!changes['selectedAsset'].firstChange) {
          this.loggerData = [];
          this.coordinates = [];
          this.data = [];
          this.rows = [];
          this.reload();
        }
      }
    }
    if(!this.dateFormat){
      this.dateFormat ='MM/DD/YYYY hh:mm:ss A';
    }
  }

  violationIcon(measure) {
   return  this._shipmentUtils.getExcursionIcon(measure.measureServiceId);
  }

  checkVoilations() {
    Object.keys(this.violations?.ViolationMap).forEach(voilatedMeasure=>{
      this.assetObject.measures.forEach(measure=>{
        if(voilatedMeasure.split(':')[1] == measure.measureCharacteristicId && (this.violations.ViolationMap[voilatedMeasure][0].violated && this.violations.ViolationMap[voilatedMeasure][0].alertStatus == 'Active')) {
          measure.voilated = true;
          measure.voilatedValue = this.violations.ViolationMap[voilatedMeasure][0].propertyValue;
          measure.voilatedTime = this.violations.ViolationMap[voilatedMeasure][0].deviceTime;
          measure.voilatedIcon = this.violationIcon(measure);
        }
      })
    })
  }

  getAsset() {
    if(!_.isEmpty(this.assetObject.id)){
      this.isLoading = true;
      // let sensorId = this.selectedAsset.properties.sensorId;
      // if(!sensorId && this.selectedAsset.properties.sensorIds?.length) {
      //   sensorId = this.selectedAsset.properties.sensorIds.filter(id=>id!='')[0];
      // }

      this.assetUtilService.getAsset(this.assetObject.id, this.selectedAsset)
      .then((response: Asset) => {
        this.isLoading = false;
        this.assetObject = response;
        this.filterEmptyMeasures();
        this.checkVoilations();
        this.checkMeasureValuesAvailable();
        if(this.isMeasureValuesAvailable) {
          if(this.shipmentDetails.shipmentNode.properties.ruleSet) {
            this.getRuleSetProfile(this.shipmentDetails.shipmentNode.properties.ruleSet);
          }
        }
        if(response.lastReportedGPSTime) {
          this.lastReportedGPSTimeFormatted = moment(response.lastReportedGPSTime).format(this.dateFormat);
        }
      }).catch(e=>{this.isLoading = false});
    }
  }

  measureChanged(measure: MeasureType){
    this.loggerData = [];
    this.coordinates = [];
    this.currentMeasure = measure;
    this.selectedMeasure = this.currentMeasure;
    this.ranges = measure.config.dateRanges;
    this.getMeasureData();
    this.buildTable()
  }

  buildTable() {
    this.columns = [
      { name: 'Asset Name', prop: 'AssetName', width: 320, visible: true, sortable: false },
      { name: 'Device ID', prop: 'LoggerId', width: 180, visible: true, sortable: false },
      { name: 'Component Name', prop: 'componentName', width: 180, visible: true, sortable: false },
      { name: this.selectedMeasure.measureLabel, prop: this.selectedMeasure.measureLabel, width: 180, visible: true, sortable: false },
      { name: 'Event Time', prop: 'EventTime', width: 180, visible: true, sortable: true }
    ];
    if(!(this.isDTPShipment)){
      this.columns.push({ name: 'Address', prop: 'Address', width: 180, visible: true, sortable: false });
      if(!this.isRestricted){
        this.columns.push({ name: 'Location', prop: 'Location', width: 180, visible: true, sortable: false })
      }
    }
    this.columns[4]['cellTemplate'] = this.assetNameTemplate;
    this.rawColumns = [...this.columns];
    this.table?.recalculate();
    this.changeDetectorRef.detectChanges();
    this.table?.recalculate();
    this.changeDetectorRef.detectChanges();
  }

  getId(row) {
    return row.id;
  }

  private getShipperLoggerData(){
    this.loggerData = [];
    this.coordinates = [];
    let args = {
      capabilities:this.selectedMeasure.measureServiceId + ':' + this.selectedMeasure.measureCharacteristicId + ',GPS_SERVICE:GeoPoint',
      from: new Date(this.selectedDateRange.startDate).getTime(),
      shipmentid: this.shipmentDetails.shipmentNode.id,
      taggedAssetIds: this.selectedAsset.properties.taggedAssetId,
      to: new Date(this.selectedDateRange.endDate).getTime(),
    };
    this.isLoading = true;
    this.shipmentService.getShipmentLogger(args).subscribe(result => {
      this.loggerData = [];
      this.isLoading  = false;
      result[0].data.slice().reverse().forEach((cData,i)=>{
      if(cData.GeoPoint) {
        this.coordinates.push({y: cData.GeoPoint.toString().split(',')[0], x: cData.GeoPoint.toString().split(',')[1]})
      } else {
        this.coordinates.push({y: '', x: ''})
      }

      this.loggerData.push({AssetName:result[0].assetName, LoggerId: cData.eventPublisherId, componentName: cData.componentName?cData.componentName:'', EventTime: this.formateDatePipe.transform(cData.Date, this.dateFormat), Address: cData.GeoPoint, Location: cData.GeoPoint });
      if(cData[this.selectedMeasure.measureCharacteristicId] != undefined && String(cData[this.selectedMeasure.measureCharacteristicId]) != '') {
        let measureValue = Number(cData[this.selectedMeasure.measureCharacteristicId])+this.currentMeasure.uom;
        if(this.currentMeasure.uom.indexOf('C') != -1 || this.currentMeasure.uom.indexOf('F') != -1) {
          measureValue = Number(cData[this.selectedMeasure.measureCharacteristicId])+this.currentMeasure.uom.trim();
        }
        if(this.currentMeasure.uom.indexOf('C') == -1 && this.currentMeasure.uom.indexOf('F') == -1 && this.currentMeasure.uom.indexOf('%') == -1) {
          measureValue = Number(cData[this.selectedMeasure.measureCharacteristicId])+' ' +this.currentMeasure.uom;
        }

        if(['doorStatus','compartmentDoorDoorStatus', 'compartmentCeilingBackDoorStatus'].includes(this.selectedMeasure.measureCharacteristicId) && cData[this.selectedMeasure.measureCharacteristicId] == true) {

          measureValue = 'Opened'
        }
        if(['doorStatus','compartmentDoorDoorStatus', 'compartmentCeilingBackDoorStatus'].includes(this.selectedMeasure.measureCharacteristicId)  && cData[this.selectedMeasure.measureCharacteristicId] == false) {
          measureValue = 'Closed'
        }
        this.loggerData[i][ this.selectedMeasure.measureLabel] = measureValue;
      } else {
        this.loggerData[i][ this.selectedMeasure.measureLabel] = '';
      }

      });
      this.table?.recalculate()
      this.changeDetectorRef.detectChanges();
      this.getAddresses();
    }, error => {
      this.isLoading  = false;
    })
  }

  private getMeasureData(){
    this.data = [];
    if(this.assetObject?.taggedAssetId && !_.isEmpty(this.currentMeasure)){
      this.assetService.getTaggedAssetCharacteristicValuesByRange(this.assetObject.taggedAssetId,
        this.currentMeasure.measureServiceId,
        this.currentMeasure.measureCharacteristicId,
        new Date(this.selectedDateRange.startDate).getTime(),
        new Date(this.selectedDateRange.endDate).getTime(),
        500)
        .then((response : any) => {
          if(response?.data && !_.isEmpty(response.data)){
            this.updateAndSetData(response);
          }
        });
    }
  }

  private updateAndSetData(response: any) {
    let tempMeasure: MeasureType = _.cloneDeep(this.currentMeasure);
    let tempLoggerData = response.data;
    let loggerData:any = [];
    this.minThreshold = [];
    this.maxThreshold = [];
    let currentMeasureViolations = _.filter(this.violations?.ViolationMap[`${tempMeasure.measureServiceId}:${tempMeasure.measureCharacteristicId}`], ((violatedMeasure: any) => {
      return (violatedMeasure.violated && violatedMeasure.alertStatus == 'Active');
    }));
    let fakeValue = null;
    if(tempMeasure.config.addSpikes){
      if (tempMeasure.config.isBinary) {
        fakeValue = 0;
      }else{
        fakeValue = tempMeasure.config.YMin;
      }
    }
    tempLoggerData = tempLoggerData.reverse();
    tempLoggerData.forEach((dataItem, i)=> {
      if(tempMeasure.config.addSpikes){
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: ((dataItem.time / 1000) - 5) * 1000,
          value: fakeValue
        });
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: dataItem.time,
          value: dataItem.value
        });
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: ((dataItem.time / 1000) + 5) * 1000,
          value: fakeValue
        });
      }else{
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: dataItem.time,
          value: dataItem.value
        });
      }
    });
    let annotations: any = {};
    let pointAnnots = [];
    let axisAnnots = [];
    let lastDatumTime = 0;
    let lastDatumTimeObj = {
      diff: 0,
      diffCount: 0,
      avg: 0
    };
    let temp = loggerData.map((item: any, index) => {
      let violations = _.find(currentMeasureViolations, (violation) => {
        return (Number(violation.propertyValue) === Number(item.value) && violation.deviceTime === item.time);
      });
      let val = this.getValue(item.value, tempMeasure);
      let icon = "assets/svgs/gateway-adder.svg"
      if(violations) {
        icon = this.currentMeasure.voilatedIcon
        pointAnnots.push({
          x: item.time,
          y: val,
          marker: {
            size: 0,
          },
          image: {
            path: this.currentMeasure.voilatedIcon,
            offsetY: -18
          }
        })
      }
      if(loggerData[index +1]) {
        lastDatumTimeObj.diff = (loggerData[index +1].time - item.time);
        lastDatumTimeObj.diffCount++;
      }

      return [item.time, val, icon, item.receiverId];
    });
    lastDatumTimeObj.avg = lastDatumTimeObj.diff ? lastDatumTimeObj.diff / lastDatumTimeObj.diffCount : loggerData[loggerData.length - 1].time + 60000;
    lastDatumTime = loggerData[loggerData.length - 1].time + ( (lastDatumTimeObj.avg > 60000) ? 60000 : lastDatumTimeObj.avg );

    if(this.currentMeasure.minThreshold) {
      this.minThreshold.push([lastDatumTime, this.getValue(tempMeasure.minThreshold, tempMeasure)]);
      axisAnnots.push({
        y: this.currentMeasure.minThreshold,
        borderColor: '#ff0b39',
        strokeDashArray: 0
      });
    }
    if(this.currentMeasure.maxThreshold) {
      this.maxThreshold.push([lastDatumTime, this.getValue(tempMeasure.maxThreshold, tempMeasure)]);
      axisAnnots.push({
        y: this.currentMeasure.maxThreshold,
        borderColor: '#ff0b39',
        strokeDashArray: 0
      })
    }
    if(axisAnnots.length) {
      annotations.yaxis = axisAnnots;
    }
    if(pointAnnots.length) {
      annotations.points = pointAnnots;
    }
    if(axisAnnots.length || pointAnnots.length) {
      annotations.position = 'back';
    }
    this.annotations = annotations;
    if(_.isEmpty(temp)){
      this.data = [];
    }else{
      this.data = [ { name: this.currentMeasure.measureLabel, data: temp }, { name: this.currentMeasure.measureLabel, data: this.minThreshold, color: '#FF0000' }, { name: this.currentMeasure.measureLabel, data: this.maxThreshold, color: '#FF0000' } ];
      this.customToolTip();
      this.buildTable();
      this.getShipperLoggerData();
    }
    
  }
  private getValue(value: CharacteristicGeoAreaValue | boolean | string | number,  tempMeasure: MeasureType){
   let temp = value;
   if(typeof value === 'boolean'){
     temp = value ? 1 : 0;
   }else if(typeof value === 'string'){
     if(value === 'false' || value === 'true'){
       temp = JSON.parse(value);
       temp = temp ? 1 : 0;
     }else{
       temp = value;
     }
   }else {
     temp = value;
   }
   return temp;
 }

 IsVoilated(value): boolean {
  if(this.currentMeasure.value == value) {
    return true
  } else {
    return false;
  }
 }

 gridColumnToggle(columns: any[]) {
  this.columns = columns.filter((col: any) => col.visible);
  this.table?.recalculate()
 }

 downloadFile(type) {
  if(type == 'csv') {
    let from = this.selectedDateRange.startDate._d.toString();
    let to = this.selectedDateRange.endDate._d.toString();
    this.csvExportService.formatAndDownloadCSVForGrid(this.getLoggerDataForExport(this.columns), 'ShipmentLoggerDataFrom-'+ this.formateDatePipe.transform(from ,  'default')+'-To-'+this.formateDatePipe.transform(from , 'default'), this.columns.map(c=>c.name.replaceAll(' ', '_')));
  }
 }

 getLoggerDataForExport(columns: any[]) : any[]{
  let filteredData = [];
  this.loggerData.forEach(l=> {
    let rowData = {};
    columns.forEach(c=>{
      rowData[c.prop] = l[c.prop];
    });
    filteredData.push(rowData);
  })
  return filteredData;
}

private customToolTip(){
  this.tooltipCustomizer = ({series, seriesIndex, dataPointIndex, w}) =>{
    let tooltipHtml = null;
    if(!_.isEmpty(this.data)){
      let tempData: any = this.data[seriesIndex].data[dataPointIndex];
      if(this.data[0].name.indexOf('Door') != -1){
        if(tempData[1] == 1) {
          tempData[1] = 'Opened'
        }
        if(tempData[1] == 0) {
          tempData[1] = 'Closed'
        }
      } else {
        tempData[1] = Number(tempData[1])
      }
      let measureValue = tempData[1] + this.currentMeasure.uom.trim();
      if(this.currentMeasure.uom && this.currentMeasure.uom.indexOf('C') == -1 && this.currentMeasure.uom.indexOf('F') == -1 && this.currentMeasure.uom.indexOf('%') == -1 && this.currentMeasure.uom !== '') {
        measureValue = tempData[1] + ' ' + this.currentMeasure.uom;
      }
      let roundColor = 'bgcolor4';
      if(tempData[2] !="assets/svgs/gateway-adder.svg"){
        roundColor = "bgcolor21";
      }
      let icon ="assets/svgs/gateway-adder.svg"
      if(tempData[2]){
        icon =tempData[2]
      }
      let relatedSensorId = tempData[3]?tempData[3]:'';
      let sensorTemplate = relatedSensorId?' (' +relatedSensorId+')':'';
      let header =  this.assetObject.name + sensorTemplate;
      let timeLabel = '<div class="p-2  flex items-center"><span>Time :</span><span class="font-bold pl-2">'+ this.formateDatePipe.transform(tempData[0], this.dateFormat) +'</span></div>'
      if(seriesIndex ==  1) {
        header = 'Minimum Threshold';
        timeLabel = '';
        roundColor = "bgcolor21";
      }
      if(seriesIndex ==  2) {
        header = 'Maximum Threshold';
        timeLabel = '';
        roundColor = "bgcolor21";
      }
      tooltipHtml = '<div class=" ">'
      + '<div class="font-bold bgcolor38 p-2">'+ header +'</div>'

      + '<div class="p-2  flex items-center"><span class="inline-block w-3 h-3  rounded-full '+roundColor+'"></span> <span class="pl-2">'+ this.data[0].name +' :</span><span class="font-bold pl-2">'+ measureValue +'</span>'+
      ' <span class="px-1 pt-0" ><img width="18" height="18" alt="" src="'+icon
      +'"/></span>'+  '</div>'
      + timeLabel
      + '</div>';
    }
    return tooltipHtml;
  }
}

filterEmptyMeasures() {
  if(this.assetObject.measures && this.assetObject.measures.length > 0){
    this.assetObject.measures = this.assetObject.measures.filter(e=> {
      if(e.value){
        return true
      } else {
        if(!this.selectedAsset.properties.feedDetails) return false;
        switch (e.measureCharacteristicId){
          case 'cargoTemp':
            e.value = this.selectedAsset.properties.feedDetails.cargotemperature;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.cargotemperatureTime;
            break;
            case 'ambientTemp':
            e.value = this.selectedAsset.properties.feedDetails.ambienttemperature;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.ambienttemperatureTime;
            break;
            case 'temperature':
            e.value = this.selectedAsset.properties.feedDetails.temperature;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.temperatureTime;
            break;
            case 'doorStatus':
            e.value = this.selectedAsset.properties.feedDetails.doorstatus;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.doorstatusTime;
            break;
            case 'rxShockMagnitude':
            e.value = this.selectedAsset.properties.feedDetails.shock;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.shockTime;
            break;
            case 'rxTilt':
            e.value = this.selectedAsset.properties.feedDetails.tilt;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.tiltTime;
            break;
            case 'rxPressure':
            e.value = this.selectedAsset.properties.feedDetails.pressure;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.pressureTime;
            break;
            case 'AA21':
            e.value = this.selectedAsset.properties.feedDetails.humidity;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.humidityTime;
            break;
            case 'rxLight':
            e.value = this.selectedAsset.properties.feedDetails.light;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.lightTime;
            break;
            case 'cargoTemp':
            e.value = this.selectedAsset.properties.feedDetails.cargotemperature;
            e.timeOfCapture = this.selectedAsset.properties.feedDetails.cargotemperatureTime;
            break;
            default:
            break;
        }
        return e.value !== undefined;
      }
    });

  }

}

getAddresses() {
  this.shipmentService.bulkReverseCoding(this.coordinates).subscribe(result => {
    this.loggerData.forEach((e: any, i: number)=> {
      let address: string = '';
      if(result[i] && result[i].features && result[i].features.length > 0 && result[i].features[0].geometry.coordinates && result[i].features[0].geometry.coordinates[0] != 0){
        if(result[i].features[0].properties.name) {
          address = address + result[i].features[0].properties.name + ','
        }
        if(result[i].features[0].properties.street) {
          address = address + result[i].features[0].properties.street + ','
        }
        if(result[i].features[0].properties.city) {
          address = address + result[i].features[0].properties.city + ','
        }
        if(result[i].features[0].properties.state) {
          address = address + result[i].features[0].properties.state + ','
        }
        if(result[i].features[0].properties.country) {
          address = address + result[i].features[0].properties.country + '-'
        }
        if(result[i].features[0].properties.postcode) {
          address = address + result[i].features[0].properties.postcode
        }
        this.loggerData[i].Address = address;
      }
    });
    this.table?.recalculate()
    this.changeDetectorRef.detectChanges();
  })
}

checkMeasureValuesAvailable() {
  this.assetObject.measures.filter(m=> m.value).length > 0?this.isMeasureValuesAvailable = true: this.isMeasureValuesAvailable = false;
}

getRuleSetProfile(rulsetId: string) {
  this.shipmentService.getRuleSetProfile(rulsetId).subscribe(result => {
    this.thresholdData = result;
    this.assetObject.measures.forEach(measure=>{
      if(this.thresholdData.eventConfig[measure.measureServiceId+':'+measure.measureCharacteristicId]){
        measure.minThreshold = this.thresholdData.eventConfig[measure.measureServiceId+':'+measure.measureCharacteristicId].min
        measure.maxThreshold = this.thresholdData.eventConfig[measure.measureServiceId+':'+measure.measureCharacteristicId].max
      }

    })
    this.measureChanged(this.currentMeasure);
  })
}

convertDateFormat(planned: any) {
  if(moment(planned).format(this.dateFormat) == "Invalid date") return planned;
    else return this.formateDatePipe.transform(planned, "Default");
}
}
