<div class="summary-section-bg p-1 mt-1 fs14 color16 border-1 summary-section-border">
  <table>
    <tr>
      <td class="font-bold w-40">Status</td>
      <td>
        {{ shipmentData?.shipmentNode?.properties?.statusWithAssetCount || "" }}
      </td>
    </tr>
    <tr>
      <td class="font-bold w-40">Actual Departure</td>
      <td>{{ depatureTime }}</td>
      <td class="pb-1 px-2">
        <div
          class="px-2"
          [ngClass]="
            getDiffTimes(
              shipmentData?.shipmentNode?.properties?.plannedDeparture,
              shipmentData?.shipmentNode?.properties?.actualDepTime
            ) > 0
              ? 'bgcolor20 color2 '
              : 'bgcolor21 color2 '
          "
        >
          {{ depatureDiff }}
        </div>
      </td>
    </tr>
    <tr>
      <td class="font-bold w-40">
        {{ isArrival ? "Actual Arrival" : "ETA" }}
      </td>
      <td>{{ arrivalTime }}</td>
      <td class="pb-1 px-2">
        <div
          class="px-2"
          [ngClass]="
            getDiffTimes(
              shipmentData?.shipmentNode?.properties?.plannedArrival,
              isArrival
                ? shipmentData?.shipmentNode?.properties?.actualArrivalTime
                : shipmentData?.shipmentNode?.properties?.eta
            ) > 0
              ? 'bgcolor20 color2 '
              : 'bgcolor21 color2 '
          "
        >
          {{ arrivalDiff }}
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="border-1 summary-section-border mt-2">
  <ng-container *ngFor="let record of summaryData">
    <div
      class="flex m-1 fs14 color16"
      *ngIf="(record.value && record.value !== '') "
    >
      <div class="font-bold w-40 flex-none">
        {{ record.label }}
      </div>
      <div>
        <a [href]="record.link" *ngIf="record.link">{{ record.value }}</a>
        <span *ngIf="!record.link">
          {{ record.value }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div class="fs14 color16 my-1" *ngIf="notes.note !== ''">
  <div class="font-bold w-40">Notes</div>
  <div class="border border-black grow p-1">
    <div>{{ notes.note }}</div>
    <div class="italic fs12" *ngIf="!(isRestricted && isDTPShipment)">
      Last Updated By: {{ notes.notesModifiedBy }} at
      {{ notes.notesModifiedAt }}
    </div>
  </div>
</div>
