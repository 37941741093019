import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexLegend,
  ApexAnnotations,
} from 'ng-apexcharts';
import { WebUIParsedConfig } from 'projects/clf-share/src/types/measure.type';


@Component({
  selector: 'cl-share-line-chart',
  templateUrl: './share-line-chart.component.html',
  styleUrls: ['./share-line-chart.component.scss'],
})
export class ShareLineChartComponent implements OnChanges {
  @Input('data') data: ApexAxisChartSeries = [];
  @Input('label') label: string = '';
  @Input('unit') unit: string = '';
  @Input('tooltip') tooltipHandler ?: any;
  @Input('options') customOptions: WebUIParsedConfig = { dateRanges: [], displayType: '', groupBy: '', is3Axes: false, isBinary: false, isConditionDataPlot: false, order: 0, rangedDefault: '', showIn: '', title: '', unitsfx: ''};
  @Input('annotations') annotations : ApexAnnotations = {};
  public options: ApexChart;
  public dataLabels: ApexDataLabels;
  public markers: ApexMarkers;
  public title: ApexTitleSubtitle;
  public fill: ApexFill;
  public yaxis: ApexYAxis;
  public xaxis: ApexXAxis;
  public tooltip: ApexTooltip;
  public legend: ApexLegend;
  public discreteMarkers = [];
  constructor() {
    this.drawLineChart();
    this.data = [];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.generateDiscreteMarkers();
    this.drawLineChart();
  }

  drawLineChart(){
    this.setLineChartOptions();
  }
  generateDiscreteMarkers(): any[] {
    this.discreteMarkers = [];
    if(this.data && this.data.length > 0){
      this.data.forEach((series, seriesIndex)=> {
        if(series.data) {
          series.data.forEach((data, dataIndex)=>{
            let baseObj= {seriesIndex: seriesIndex, dataPointIndex: dataIndex};
            if(data[2] && typeof data[2] === 'object'){
              this.discreteMarkers.push({
                ...baseObj,
                ...data[2]
              })
            } else if(data.dataPointStyle && typeof data.dataPointStyle === 'object'){
              this.discreteMarkers.push({
                ...baseObj,
                ...data.dataPointStyle
              })
            }
          })
        }

      })
    }
    return this.discreteMarkers;
  }
  setLineChartOptions(){
    var self = this;
    this.dataLabels = { enabled: false };
    let markerObj: any = {};
    if(this.discreteMarkers.length) {
      markerObj.discrete = this.discreteMarkers;
    } else {
      markerObj.size = 4;
      markerObj.strokeWidth = 0.5;
    }
    this.markers = markerObj;
    this.xaxis = {
    type: "datetime",
    labels: {
      show: true,
      formatter: function(val, timestamp) {
          return moment(new Date(timestamp)).format("MMM DD hh:mm");
      }
    }
  };
    this.title = { text: "", align: "center" };
    this.fill = { type: "gradient", gradient: { shadeIntensity: 0, inverseColors: false, opacityFrom: 0, opacityTo: 0, stops: [0, 100] } };
    this.legend = { show: false, position: "top", horizontalAlign: "right" };
    this.options = {
      type: "area",
      stacked: false,
      height: 300,
      zoom: {
        type: "x",
        enabled: false,
        autoScaleYaxis: true
      },
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    };
    this.yaxis = {
      labels: {
        formatter: function(val) {
          if(self.unit.indexOf('C') != -1 || self.unit.indexOf('F') != -1 || self.unit.indexOf('%') != -1) {
            return !(val % 1) ? val + '' + self.unit.trim() : val.toFixed(2) + '' + self.unit.trim();
          } else {
            return !(val % 1) ? val + ' ' + self.unit : val.toFixed(2) + ' ' + self.unit;
          }

        }
      },
      title: {
        text: this.label
      },
      max: (max) => (this.data?.[0].data?.length == 1 && +this.data?.[0].data?.[0]?.[1] == max) ? max+1 : max
    };
    if(this.customOptions.hasOwnProperty('YMax') && _.isNumber(this.customOptions.YMax)){
      this.yaxis.max = this.customOptions.YMax;
    }
    if(this.customOptions.hasOwnProperty('YMin') && _.isNumber(this.customOptions.YMin)){
      this.yaxis.min = this.customOptions.YMin;
    }
    this.tooltip = {
      shared: false,
      intersect: true,
      fillSeriesColor: true,
      y: {
        formatter: function(val) {
          return val + '';
        }
      }
    };
    if(typeof this.tooltipHandler  === 'function'){
      this.tooltip.custom = this.tooltipHandler;
    }
  }
}
