import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { PersistenceService } from 'projects/clf-share/src/services/sh-persistence.service';

@Component({
  selector: 'cl-share-grid-column-toggle',
  templateUrl: './share-grid-column-toggle.component.html',
  styleUrls: ['./share-grid-column-toggle.component.scss'],
})
export class ShareGridColumnToggleComponent implements OnInit {
  @Input() columnList: any;
  @Input() page?: string;
  @Output() toggleColumns = new EventEmitter();
  public toolTip: string = 'Manage table settings';
  public showToggleContainer: boolean = false;
  constructor(
    private persistenceStore: PersistenceService
  ) { }

  ngOnInit(): void {
  }

  toggleContainer() {
    this.showToggleContainer = !this.showToggleContainer;
  }

  toggleColumn(col: any) {
    col.visible = !col.visible;
    let columnList :any = [...this.columnList];
    if (!col.visible) {
      let index: number = _.findIndex(columnList, (column: any) => col.prop === column.prop);
      columnList.splice(index, 1);
    }
    let columnsStore = columnList.filter(item => item.visible).map(item => item.name);
  //  this.persistenceStore.setGridColumns(this.page, columnsStore);
    this.toggleColumns.emit(columnList);
  }

}
