import { Injectable } from '@angular/core';
// import { DateFromStringPipe } from '@cl/common/pipes/date-from-string.pipe';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AssetStatus, BoundStatus } from '../enums/asset-status.enum';
import { Asset } from '../types/asset.type';
import { CharacteristicGeoAreaValue } from '../types/characteristic-value.type';
import { MeasureType, WebUIParsedConfig } from '../types/measure.type';
import { Characteristic, SensorType, Service, WebUIConfig } from '../types/sensor-type.type';
import { Sensor, SensorBasicInformation, SensorDeviceEvent, SensorDeviceEvents, SensorTaggedAssetAssignment, UnparsedSensorTaggedAssetAssignment } from '../types/sensor.type';
import { PropertyService } from './property.service';
import { SensorService } from './sensor.service';



@Injectable({
  providedIn: 'root'
})
export class SensorUtilService {
  batteryCharacteristicId : string = '2A19';
  batteryServiceId : string = '180F';
  constructor(private sensorService: SensorService, private propertyService: PropertyService) { }
  private getWebUiConfig(webUIConfig: WebUIConfig): WebUIParsedConfig{
    let tempDateRanges: any, tempDateRangesMap: any = {}, tempDefaultRangeLabel: string, rangedDefault: number;
    if(webUIConfig.hasOwnProperty('rangedDefault')){
      rangedDefault =  parseInt(webUIConfig.rangedDefault, 10);
    }
    try{
      tempDateRanges = JSON.parse(webUIConfig.dateRanges);
    }catch(e){
      tempDateRanges = webUIConfig.dateRanges.replace(/\\/g, "");
      tempDateRanges = JSON.parse(tempDateRanges);
    }
    tempDateRanges.forEach((range, index) => {
      var tempLabel = 'Last ' + range[0] + ' ' + range[1];
      tempDateRangesMap[tempLabel] = [moment().subtract(range[0], range[1]), moment()]; //TODO do we need date range by timezone
      if (index === rangedDefault) {
        tempDefaultRangeLabel = tempLabel;
      }
    });
    let config: WebUIParsedConfig = {
      is3Axes : (webUIConfig.is3Axes === 'true'),
      isBinary : (webUIConfig.isBinary === 'true'),
      isConditionDataPlot: (webUIConfig.isConditionDataPlot === 'true'),
      order : parseInt(webUIConfig.order, 10),
      dateRanges : tempDateRangesMap,
      rangedDefault : tempDefaultRangeLabel,
      displayType: webUIConfig.displayType,
      groupBy: webUIConfig.groupBy,
      showIn: webUIConfig.showIn,
      title: webUIConfig.title,
      unitsfx: webUIConfig.unitsfx
    }

    if(webUIConfig.hasOwnProperty('YMin')){
      config.YMin = parseInt(webUIConfig.YMin, 10);
    }
    if(webUIConfig.hasOwnProperty('YMax')){
      config.YMax = parseInt(webUIConfig.YMax, 10);
    }
    if(webUIConfig.hasOwnProperty('YInterval')){
      config.YInterval = parseInt(webUIConfig.YInterval, 10);
    }
    if(webUIConfig.hasOwnProperty('dependencyMetric')){
      config.dependencyMetric = webUIConfig.dependencyMetric;
    }
    if(webUIConfig.hasOwnProperty('addSpikes')){
      config.addSpikes = JSON.parse(webUIConfig.addSpikes);
    }
    return config;
  }
  private getMeasureTypes(sensorType: any, type: string, groupBy: string, displayType: string, ignoreGroupBy?: boolean): any{
    let measures: any = [];
    sensorType.services.forEach((service: Service)=>{
      service.characteristics.forEach((characteristic: Characteristic) => {
        let status: boolean = false;
        if(ignoreGroupBy){
          status = (characteristic.webUIConfig?.showIn === type);
        }else{
          status = (!_.isEmpty(characteristic.webUIConfig) && characteristic.webUIConfig.groupBy === groupBy && characteristic.webUIConfig.showIn === type);
        }
        if(status){
          let uom = !_.isEmpty(characteristic.configParams?.unit) ? characteristic.configParams?.unit : characteristic.webUIConfig?.unitsfx;
          if(_.isEmpty(uom)){
            uom = '';
          }
          if(service.type === 'com.cloudleaf.service.temperature') {
            let unit = this.propertyService.getSetting('ui.temp.unit', false, 'C');
            uom = ` ${unescape('%B0')}${unit}`;
          }
          let temConfig = this.getWebUiConfig(characteristic.webUIConfig);
          if(characteristic.webUIConfig.hasOwnProperty('dependencyMetric')){
            temConfig['dependencyTitle'] = characteristic.webUIConfig['dependencyTitle'] ? characteristic.webUIConfig['dependencyTitle'] : characteristic.webUIConfig['title'] ;
          }
          measures.push({
            measureServiceId: service.identifier,
            measureCharacteristicId: characteristic.identifier,
            measureLabel: characteristic.webUIConfig.title,
            uomLabel: characteristic.name,
            uom: uom,
            config: temConfig
          });
        }
      });
    });
    return measures;
  }
  getAssetMeasureTypes(sensorType: SensorType): any{
    let measures: any = this.getMeasureTypes(sensorType, 'asset', 'Environmental Conditions', 'graph');
    measures = _.sortBy(measures, 'config.order');
    return measures;
  }
  getSensorMeasureTypes(sensorType : SensorType): any{
    let measures: any = this.getMeasureTypes(sensorType, 'sensor', null, null, true);
    //let measures : MeasureType[] =  [...this.getAssetMeasureTypes(sensorType), ...this.getMeasureTypes(sensorType, 'sensor', '', 'graph'), ...this.getMeasureTypes(sensorType, 'sensor', 'Debug Conditions', 'graph')];
    // measures = _.sortBy(measures, 'config.order');
    return _.compact(measures);
  }
  generateImageURL(sensorBasicInformation: SensorBasicInformation): string {
    let tagImageRef = 'tag_default';
    if (!_.isEmpty(sensorBasicInformation) && !_.isEmpty(sensorBasicInformation.properties)) {
      if (sensorBasicInformation.properties.model && sensorBasicInformation.properties.sku) {
        tagImageRef = sensorBasicInformation.properties.model + '_' + sensorBasicInformation.properties.sku;
      } else if (sensorBasicInformation.properties.type) {
        tagImageRef = sensorBasicInformation.properties.type;
      }
    }
    tagImageRef = tagImageRef.replaceAll(' ', '_');
    return `https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/${tagImageRef}.jpg`;
  }
  getAssetAssignments(assignments) {
    assignments = _.reverse(_.sortBy(assignments, 'updatedDate'));
    return assignments.map((assignment: UnparsedSensorTaggedAssetAssignment)=>{
      return {
        assetExternalId: assignment?.assetExternalId,
        assetId: assignment?.assetId,
        assetName: assignment?.assetName,
        createdBy: assignment?.createdBy,
        taggedAssetId: assignment?.id,
        status: BoundStatus[assignment?.status],
        sensorId: assignment?.tag_id,
        updatedDate: assignment?.updatedDt ? new Date(assignment.updatedDt) : null
      }
    });
  }
  private getSensorObject(sensorBasicInformation: SensorBasicInformation): Sensor {
    let temp: Sensor = {
      id: sensorBasicInformation.id,
      name: sensorBasicInformation?.properties?.name,
      accountId: sensorBasicInformation?.properties?.accountId,
      owner: sensorBasicInformation?.properties.owner,
      status: AssetStatus[sensorBasicInformation?.properties.status],
      model: sensorBasicInformation?.properties?.model,
      assetId: sensorBasicInformation?.properties?.assetId,
      type: sensorBasicInformation?.properties?.type,
      gatewayName: sensorBasicInformation?.properties?.gatewayName,
      boundDate: sensorBasicInformation?.properties?.modifiedAt ? this.transform(sensorBasicInformation.properties.modifiedAt): null,
      assignments: [],
      imageUrl: this.generateImageURL(sensorBasicInformation),
      fwVersion: sensorBasicInformation?.properties?.fwrev,
      vendor:  sensorBasicInformation?.properties?.vendor,
      eventProfileName: sensorBasicInformation?.properties?.eventProfileName,
      accountName: sensorBasicInformation?.properties?.accountName,
      sku: sensorBasicInformation?.properties?.sku,
      deviceType: ''
    };
    return temp;
  }
  private getSensorDeviceEvents(sensorDeviceEvents: SensorDeviceEvents, sensor: Sensor) : Sensor {
    let batteryEvent: SensorDeviceEvent = _.find(sensorDeviceEvents.events, { name: 'tag.batterylevel'});
    let overallStatus: SensorDeviceEvent = _.find(sensorDeviceEvents.events, { name: 'overallStatus'});
    sensor.attributes = {rssi: null, rssiCapturedAt: null, rssiUom: '', battery: null, batteryCapturedAt: null, batteryUom: '', overallStatus: null, overallStatusCapturedAt: null};
    sensor.attributes.battery = batteryEvent? parseInt(batteryEvent.value, 10) : null;
    sensor.attributes.batteryCapturedAt = batteryEvent ? new Date(batteryEvent.time) : null;
    sensor.attributes.overallStatus = overallStatus?.value;
    sensor.attributes.overallStatusCapturedAt = overallStatus? new Date(overallStatus.time): null;
    return sensor;
  }
  getSensor(sensorId: string): Promise<Sensor>{
    return new Promise<Sensor>((resolve, reject) => {
      let sensorObject: Sensor = {id: sensorId, assignments : []};
      Promise.all([
        this.sensorService.getSensorInformation(sensorId),
        this.sensorService.getSensorDeviceEvents(sensorId)
      ])
      .then((response : [SensorBasicInformation, SensorDeviceEvents]) => {
        let result: Sensor = sensorObject;
        if(!_.isEmpty(response[0])){
          result = this.getSensorObject(response[0]);
        }
        if(!_.isEmpty(response[1])){
          result = this.getSensorDeviceEvents(response[1], result);
        }
        if(!_.isEmpty(result.type)){
          this.sensorService.getSensorTypeDetails(result.type)
          .then((sensorTypeResponse: SensorType)=>{
            let asset: Asset = { id: null, name: null, externalId: null, status: null, measures: [] };
            asset.measures = this.getSensorMeasureTypes(sensorTypeResponse);
            result.asset = asset;
            result.asset.taggedAssetId = response[0]?.properties?.taggedAssetId;
            result = this.updateSensorWithBatteryMeasure(result);
            result.deviceType = sensorTypeResponse.type;
            resolve(result);
          })
          .catch(()=>{
            resolve(result);
          });
        } else{
          resolve(result);
        }
      }).catch(() => {
        resolve(sensorObject);
      });
    });
  }
  private updateSensorWithBatteryMeasure(sensor: Sensor) : Sensor{
    if(!_.isEmpty(sensor?.asset?.measures)){
      let index = _.findIndex(sensor.asset.measures, {measureCharacteristicId: this.batteryCharacteristicId, measureServiceId: this.batteryServiceId});
      if(index > -1){
        let measure: MeasureType = sensor.asset.measures[index];
        measure.value = sensor.attributes.battery;
        measure.timeOfCapture = sensor.attributes.batteryCapturedAt;
        measure.config.deviceMetric = true; // This will be used to call the heartbeat device events
        sensor.attributes.batteryUom = measure.uom;
      }
    }
    return sensor;
  }
  getSensorAssignments(sensorId: string): Promise<SensorTaggedAssetAssignment[]>{
    return new Promise<SensorTaggedAssetAssignment[]>((resolve, reject) => {
      this.sensorService.getTaggedAssetAssignment(sensorId)
      .then((response : UnparsedSensorTaggedAssetAssignment[]) => {
        if(!_.isEmpty(response)){
          resolve(this.getAssetAssignments(response));
        }else{
          resolve([]);
        }
      }).catch(() => {
        resolve([]);
      });
    });
  }
  getValue(value: CharacteristicGeoAreaValue | boolean | string | number,  tempMeasure: MeasureType): number{
    // TODO need to configure value based on tempMeasure.config
   let temp: any = value; //TODO Need to update the type here
   if(typeof value === 'boolean'){
     temp = value ? 1 : 0;
   }else if(typeof value === 'string'){
     if(value === 'false' || value === 'true'){
       temp = JSON.parse(value);
       temp = temp ? 1 : 0;
     }else{
       temp = value;
     }
   }else {
     temp = value;
   }
   return temp;
 }

  getDeviceEventsByRange(sensorId: string, measure: MeasureType, deviceType: string, from: number, to: number) : Promise<[[number, number]]>{
    return new Promise<any>((resolve)=>{
      this.sensorService.getDeviceEventCharacteristicValuesByRange(sensorId, deviceType, from, to, 500)
      .then((response: SensorDeviceEvents)=>{
        if(!_.isEmpty(response?.events)){
          let data = [];
          data = response.events.map((item: SensorDeviceEvent) => {
            return [item.time, this.getValue(item.value, measure)];
          });
          resolve(data);
        }else{
          resolve([]);
        }
      });
    });
  }
  getRssiEvents(taggedAssetId: string, from: number, to: number, eventTypes: string[]): Promise<any>{
    return new Promise<any>((resolve)=>{
      const promises: Promise<any>[] = [];
      eventTypes.forEach((eventType: string)=>{
        promises.push(this.sensorService.getRssiEvents(taggedAssetId, eventType, from, to, 500));
      });
      Promise.all(promises)
      .then((response: any[])=>{
        eventTypes.forEach((eventType: string, index : number)=>{
          let data = response[index];
          if(!_.isEmpty(data)){
            data = data.data;
            if(!_.isEmpty(data)){
              data.forEach((item: any)=>{
                item.eventType = eventType;
                item.x = item.time;
                item.y = item.value;
              });
            }
          }
        });
        let temp: any[] = _.flatten(_.map(response, 'data'));
        resolve(temp);
      })
      .catch(()=>{
        resolve([]);
      });
    });
  }
  transform(value: string, ...args: unknown[]): Date {
    try{
      return value ? new Date(JSON.parse(value)) : null;
    }catch(e){
      return null;
    }
  }
}
