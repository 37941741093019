import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';
import { ShipmentUtilsService } from 'projects/clf-share/src/services/shipment-utils.service';
import { UserService } from 'projects/clf-share/src/services/user.service';
import { ShareService } from 'projects/clf-share/src/share.service';
import { MILESTONE_ZONES, ShipmentTrackPoint, WayPointStatus } from 'projects/clf-share/src/types/shipment.types';



@Component({
  selector: 'cl-share-tracking-progress',
  templateUrl: './share-tracking-progress.component.html',
  styleUrls: ['./share-tracking-progress.component.scss'],
})
export class ShareTrackingProgressComponent implements OnInit {
  @Input('shipmentDetails') shipmentDetails: any;
  @Output() selectedAssetIndex = new EventEmitter<string>();
  @Input() winkedAssetId: string;
  @Input() isRestricted:boolean;
  tempValue :any;
  isHideTimeZone = false;
  isTimezoneAvailable = true;
  mileStoneZones: { name: string; id: MILESTONE_ZONES }[] = [
    {
      name: 'Local Event Time',
      id: MILESTONE_ZONES.LOCALE,
    },
    {
      name: 'Origin',
      id: MILESTONE_ZONES.ORIGIN,
    },
    {
      name: 'Destination',
      id: MILESTONE_ZONES.DESTINATION,
    },
    {
      name: 'User Setting',
      id: MILESTONE_ZONES.USER,
    },
  ];

  assets = [];
  selectedAsset: any;

  originChecks = ['Ready to Ship', 'Planned', 'PLANNED', 'Drafted'];
  destinationChecks = ['At Destination'];
  completedChecks = ['Completed'];

  trackingInfo: ShipmentTrackPoint[] = [];

  currentMileStone = this.mileStoneZones[0];
  isReverseOrder = false;

  trackingID = '';

  isDTPShipment = true;

  constructor(
    private _shipmentUtils: ShipmentUtilsService,
    private _shipmentService: ShareService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.assets = this.shipmentDetails.assetNodes;
    this.selectedAsset = this.assets[0];
    if (changes['winkedAssetId'] && !changes['winkedAssetId'].firstChange && this.winkedAssetId) {
      this.shipmentDetails.assetNodes.forEach((assetNode) => {
        if (assetNode.id == this.winkedAssetId) {
          this.selectedAsset = assetNode;
        }
      });
    }
    this.tempValue = this.selectedAsset
    this.changeAsset(this.selectedAsset);
  }

  async changeAsset(asset) {
    this.tempValue =asset
    const assetId = asset.id;
    this.isHideTimeZone = false;
    this.isTimezoneAvailable = false;
    this.selectedAssetIndex.emit(assetId);

    const assetObj = this.shipmentDetails.shipmentNode.properties.assets.find(
      (asset) => asset.assetId === assetId
    );
    this.trackingID = assetObj?.trackingNumber || '';
    this.isDTPShipment = this.shipmentDetails.shipmentNode.properties.parentEntityTypeKey == 'dtpshipment';

    // First check with asset level if it is not present take shipment level status
    const currentStatus =
      asset.properties?.statusCode ||
      this.shipmentDetails.shipmentNode.properties.statusCode;

    // This check is for if API does not have these properties we are no longer to support rendering a route plan
    if (!currentStatus) return;

    const isAtInTransit = currentStatus === 'In-Transit';
    const isAtOrigin = this.originChecks.includes(currentStatus);
    const isAtDestination = this.destinationChecks.includes(currentStatus);
    const isCompleted = this.completedChecks.includes(currentStatus);

    let milestonePoints = [];

    const shipmentDateRange = this._shipmentUtils.getShipmentDateRange(
      this.shipmentDetails.shipmentNode.properties
    );

    const [assetMileStones] = await this._shipmentService.getAssetMileStones({
      assetID: asset.properties.taggedAssetId,
      from: shipmentDateRange.dateRange.startDate,
      to: shipmentDateRange.dateRange.endDate,
    });

    if (assetMileStones && assetMileStones.data) {
      milestonePoints = assetMileStones.data.reverse();
    }

    if(typeof this.shipmentDetails.routeNode.properties.locationDetails == 'string'){
      try {
        this.shipmentDetails.routeNode.properties.locationDetails = JSON.parse(this.shipmentDetails.routeNode.properties.locationDetails)
      } catch (error) {
      }
    }

    const routePoints =
      this.shipmentDetails.routeNode.properties.locationDetails.map((route) => {
        const address = route?.routeGeojson
          ? JSON.parse(route.routeGeojson.replace(/\\+/g, ''))?.address
          : '';
        return {
          name: route.areaName,
          address: address,
        };
      });

    const originPoint = routePoints.shift();
    const destinationPoint = routePoints.pop();

    // First milestone is Origin
    const mileStones: ShipmentTrackPoint[] = [
      {
        title: 'Origin',
        status: isAtOrigin ? WayPointStatus.Current : WayPointStatus.Completed,
        message: originPoint.address,
        tooltip: (this.isDTPShipment && this.isRestricted) ? [] : [
          {
            heading: 'Location: ',
            message: originPoint.address,
          },
        ],
      },
    ];

    const totalMileStonePoints = milestonePoints.length;
    let timeZoneAvailabilityCount = 0;
    milestonePoints.forEach((mileStonePoint, indx) => {
      const pointData = JSON.parse(mileStonePoint.value);

      if (!pointData.location) this.isHideTimeZone = true;

      if (pointData?.isTimezoneAvailable) timeZoneAvailabilityCount++;

      const tooltip: { heading: string; message: string }[] = [];

      if (pointData.tag)
        tooltip.push({ heading: 'Milestone: ', message: pointData.tag });

      if (pointData.subtagMessage)
        tooltip.push({
          heading: 'Sub-Milestone: ',
          message: pointData.subtagMessage,
        });
        if(!this.isRestricted){ 
          if (pointData.location){
            tooltip.push({ heading: 'Location: ', message: pointData.location });
          }
    
          if (pointData?.tag === 'Delivered' && pointData.signed_by)
            tooltip.push({
              heading: 'Proof of Delivery Info: ',
              message: `Signed by: ${pointData.signed_by} [${pointData?.checkpointLocalTime}]`,
            });
        }

   

      const mileStone: ShipmentTrackPoint = {
        title: pointData.message,
        message: pointData.checkpointLocalTime,
        localeTime: pointData.checkpointLocalTime,
        checkpointTime: pointData.checkpointTime,
        status: isAtOrigin
          ? WayPointStatus.Pending
          : isAtDestination ||
            isCompleted ||
            (isAtInTransit && indx < totalMileStonePoints - 1)
          ? WayPointStatus.Completed
          : WayPointStatus.Current,
        tooltip: (this.isDTPShipment && this.isRestricted) ? [] : tooltip,
      };

      mileStones.push(mileStone);
    });

    mileStones.push({
      title: 'Destination',
      status: isAtDestination
        ? WayPointStatus.Current
        : isCompleted
        ? WayPointStatus.Completed
        : WayPointStatus.Pending,
      message: destinationPoint.address,
      tooltip: (this.isDTPShipment && this.isRestricted) ? [] :[
        {
          heading: 'Location: ',
          message: destinationPoint.address,
        },
      ],
    });

    this.trackingInfo = [...mileStones];

    if (
      totalMileStonePoints > 0 &&
      totalMileStonePoints === timeZoneAvailabilityCount &&
      !this.shipmentDetails.routeNode?.properties?.dynamicRoute
    ) {
      this.isTimezoneAvailable = true;
    }
  }

  changeMileStone(mileStone: { name: string; id: MILESTONE_ZONES }) {
    this.currentMileStone = mileStone;
    let zoneId = '';

    const routes = this.shipmentDetails.routeNode.properties.locationDetails;

    if (this.currentMileStone.id === MILESTONE_ZONES.ORIGIN) {
      if (routes[0].zoneId) this.changeMilestoneTimeZones(routes[0].zoneId);
      else this.getZoneId(routes[0].areaId);
    } else if (this.currentMileStone.id === MILESTONE_ZONES.DESTINATION) {
      const destinationIndex = routes.length - 1;
      if (routes[destinationIndex].zoneId)
        this.changeMilestoneTimeZones(routes[destinationIndex].zoneId);
      else this.getZoneId(routes[destinationIndex].areaId);
    } else if (
      [MILESTONE_ZONES.LOCALE, MILESTONE_ZONES.USER].includes(
        this.currentMileStone.id
      )
    ) {
      this.changeMilestoneTimeZones(zoneId);
    }
  }

  async getZoneId(areaId: string) {
    const [zoneId] = await this._shipmentService.getZoneIdByAreaId(areaId);
    this.changeMilestoneTimeZones(zoneId.result);
  }

  changeMilestoneTimeZones(zoneId: string) {
    if (this.currentMileStone.id === MILESTONE_ZONES.USER)
      zoneId = this._userService.getTimeZone();

    this.trackingInfo.forEach((trackPoint) => {
      if (trackPoint.title === 'Origin' || trackPoint.title === 'Destination')
        return;

      if (this.currentMileStone.id === MILESTONE_ZONES.LOCALE)
        trackPoint.message = trackPoint.localeTime;
      else
        trackPoint.message = this.convertDateFormat(
          trackPoint.checkpointTime,
          zoneId
        );
    });
  }

  changeChronologicalOrder(isReverse: boolean) {
    this.isReverseOrder = isReverse;
  }

  convertDateFormat(date: number, zoneId: string) {
    return moment(date).tz(zoneId).format('MM/DD/YY, hh:mm A');
  }
  displayFn(item) {
    return item? item.properties?.name : ""
  }
  matClose(){
     this.selectedAsset=this.tempValue;
  }
  matOpen(){
    this.selectedAsset=undefined;
 }
}
