<div class="px-2 w-full h-full">
  <div class="flex gap-2 items-center">
    <div class="text-left">
      <div>Select Asset</div>
    </div>
    <div>
      <div class="user-detail__item">
        <div class="dropdown-input">
        <input
        [matAutocomplete]="assetSel"
        placeholder="Select asset"
        class="basic-input drp-search"
        type="search"
        (click)="matOpen()"
        [(ngModel)]="selectedAsset" 
      />
      <mat-autocomplete 
      (optionSelected)="changeAsset($event.option.value)"
      (closed)="matClose()"
       #assetSel="matAutocomplete" [displayWith]="displayFn">
        <ng-container >
          <mat-option
            *ngFor="let  asset of assets | search : selectedAsset"
            [value]="asset"
          >
            <span>{{asset.properties.name }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      </div>
    </div>
    </div>
  </div>

  <div class="my-2 flex grow h-full gap-3">
    <div class="overflow-scroll w-full" style="height: calc(100% - 50px)">
      <cl-share-shipment-tracker [data]="trackingInfo"></cl-share-shipment-tracker>
    </div>
  </div>
</div>
