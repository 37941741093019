import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiProviderService } from '../../services/api-provider.service';
import { PropertyService } from '../../services/property.service';
import { ShareService } from '../../share.service';
import { ShipmentUtilsService } from '../../services/shipment-utils.service';
import { UserService } from '../../services/user.service';
import { TenantUtilService } from '../../services/tenant-util.service';
import { TenantService } from '../../services/tenant.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'cl-share-shipment',
  templateUrl: './share-shipment.component.html',
  styleUrls: ['./share-shipment.component.scss'],
})
export class ShareShipmentComponent implements OnInit {
  validUrl = false;
  decriptId = '';
  shipmentDetails :any;
  shipmentId: string;
  entityTypeKey:string;
  entityTypeKeyCS:string;
  winkedAssetId: string;
  isInitialLoad = true;
  isLoading =false;
  isRestricted = true;
  mac = '';
  compositeShipmentId='';
  compositeShipmentData:any;
  defaultShareUrlLogo = 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png';
  shareUrlLogo = '';

  defaultTitleConfig = {
    title: 'ParkourSC',
    favicon: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/favicon.ico'
  }

  titleConfig: typeof this.defaultTitleConfig = {
    title: '',
    favicon: ''
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ShareService,
    private apiProvider: ApiProviderService,
    private propertyService:PropertyService,
    private shipmentUtils: ShipmentUtilsService,
    private userService: UserService,
    private tenantUtilService: TenantUtilService,
    private tenantService: TenantService,
    private renderer: Renderer2,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.decriptId = params.get('id');
      if (this.decriptId) {
        this.apiService.getSharedDetails(this.decriptId).subscribe(
          async (data: any) => {
            await this.propertyService.getTenantSettingsData();
            this.validUrl = true;    
            this.isRestricted = data['body']['restricted'];
            if( data['body']['entityType']=="COMPOSITE"){
              this.compositeShipmentId =data['body']['entityId'];
              this.entityTypeKeyCS =data['body']['entityTypeKey']
              this.getCompositeShipmentData();
            }else{
              this.entityTypeKey =data['body']['entityTypeKey']
              this.shipmentId = data['body']['entityId'];
              this.getShipmentDetails();
            }
            this.setupConfigurations();
          }
        );
      }
    });
    if(window.navigator.platform.indexOf('Mac') != -1){
    this.mac ='mac'
    }
  }
  onSelectShipment(Obj){
    this.shipmentId =Obj.id
    this.entityTypeKey=Obj.type
    this.getShipmentDetails()
  }
  getCompositeShipmentData() {
    this.apiService
      .getCompositeShipmentDetails(
        this.compositeShipmentId,
        this.entityTypeKeyCS
      )
      .subscribe((res:any) => {
        const duration = res.duration
          ? this.shipmentUtils.dwellTimeFormatter(
              res.duration,
              false,
              true,
              true,
              null
            )
          : '';
        this.compositeShipmentData = res;
        this.compositeShipmentData.duration = duration;
        this.shipmentId= this.compositeShipmentData?.subShipments[0]?.id 
        this.entityTypeKey= this.compositeShipmentData?.subShipments[0]?.entityTypeKey 
        if(this.shipmentId) this.getShipmentDetails()
      });
  }

  getShipmentDetails(){
    this.isLoading= true
    this.apiService.getShipmentDetails(this.shipmentId,this.entityTypeKey).subscribe(
      (res)=>{
        this.isLoading= false
        this.shipmentDetails =res
        if (!this.winkedAssetId) {
          this.winkedAssetId = this.shipmentDetails.assetNodes[0].id;
        }
        if (!this.isInitialLoad) {
          this.reloadWinkedAsset();
        }
        this.isInitialLoad = false;
      });
  }
  reloadWinkedAsset() {
    const prevWink = this.winkedAssetId;
    this.winkedAssetId = '';
    setTimeout(() => {
      this.winkedAssetId = prevWink.slice(0, prevWink.length);
    }, 1);
  }
  getWinkedAssetId(event) {
    this.winkedAssetId = event;
  }
    // NOTE: The below logic is for setup theming and branding for share eURL
    async setupConfigurations(){
      this.initLoginSettings()
      await this.userService.getLoggedInUserData();
      
      // await this.tenantUtilService.getTenants(this.userService.getUserId())
      // await this.propService.getTenantSettingsData();
      await Promise.all([
        this.propertyService.getTranslations(),
        this.propertyService.getCustomizedTheme(),
        this.propertyService.getAppSettings(),
      ]);
      let isHavingPlatformSettings = false;
      if(this.propertyService.mergedBulkSettings && this.propertyService.mergedBulkSettings.length){
        this.propertyService.mergedBulkSettings.forEach(setting => {
          if(setting.key == 'common.shareUrlLogo'){
            isHavingPlatformSettings = true;
            this.defaultShareUrlLogo = setting.value as string;
          }
        });
      }
      if(!isHavingPlatformSettings){
        try {
          const res:any = await this.userService.getTenantLogo(this.userService.getTenantId());
          if(res.logo){
            this.defaultShareUrlLogo = res.logo
          }
        } catch (error) {
        }
      }
      this.shareUrlLogo = this.defaultShareUrlLogo;
    }

    async initLoginSettings(){
      try {
        const response = await this.propertyService.getLoginBulkProperties();
        if(response && response.length) {
          response.map((item)=>{
            if(item.key === 'domain.applicationName') {
              this.defaultTitleConfig.title = item.value;
            } else if(item.key === 'domain.applicationFavicon') {
              this.defaultTitleConfig.favicon = item.value;
            }
          })
        }
        this.applyTitleConfig()
      } catch (error) {
        this.applyTitleConfig()      
      }
    }

    applyTitleConfig(){
      this.titleConfig = this.defaultTitleConfig;
      this.titleService.setTitle(this.titleConfig.title);
      const faviconElem = document.getElementById('favicon');
      this.renderer.setAttribute(faviconElem, 'href', this.titleConfig.favicon);
    }
}
