export enum AssetStatus {
  READY = 'READY',
  MONITORED= 'MONITORED',
  UNMONITORED = 'UNMONITORED',
  ACTIVE  = 'MONITORED',
  INACTIVE = 'UNMONITORED'
}

export enum BoundStatus {
  Bound = 'Bound',
  Unbound = 'Unbound'
}
