import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment';
import { PropertyService } from 'projects/clf-share/src/services/property.service';
import { ShipmentTrackPoint } from 'projects/clf-share/src/types/shipment.types';

type Status = 'complete' | 'current' | 'pending';
@Component({
  selector: 'cl-share-shipment-tracker',
  templateUrl: './share-shipment-tracker.component.html',
  styleUrls: ['./share-shipment-tracker.component.scss'],
})
export class ShareShipmentTrackerComponent implements OnInit, OnChanges {
  @Input('data') trackingData: ShipmentTrackPoint[];

  trackingInfo: ShipmentTrackPoint[] = [];
  dateDisplayFormat:any
  @Input('isReverse') isReverse: boolean = false;
  @Input() currentMileStone:any;

  constructor(private pService: PropertyService) {}

  ngOnInit(): void {
    this.trackingInfo = [...this.trackingData];
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('dd', 'DD'); // replacing dd with DD because only this format will work in date picker
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');  // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
      this.returnAdress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.trackingInfo = this.isReverse
      ? [...this.trackingData].reverse()
      : [...this.trackingData];
      this.returnAdress();
  }
  convertDateFormat(planned: any) {
    if(this.currentMileStone?.name != "User Setting") return planned
    else if(moment(planned).format(this.dateDisplayFormat) == "Invalid date") return planned;
    else return moment(planned).format(this.dateDisplayFormat);
  }
  returnAdress(){
        if(this.trackingData){
          this.trackingInfo.forEach(el=>{
            if(el.title == "Origin" || el.title == "Destination"){
              let arry =  el.message.split(',');
              arry = arry.slice(-2)
              el.message= arry.join();
            }
          })
        }
      }
}
