<div class="env-conds">
  <i
    (click)="showEnv = !showEnv"
    class="fa fa-chevron-right float-left color4 mr-4"
    [ngClass]="{
      'rotate-Icon': showEnv === true
    }"
  ></i
  >Environmental Conditions
</div>
<div
  [ngClass]="!showEnv ? 'hidden' : ''"
  class="pb-2"
  [ngStyle]="{ 'min-height': data.length >0 ? '630px' : 'inherit' }"
>
  <div *ngIf="assetObject.measures && assetObject.measures.length !== 0 && isMeasureValuesAvailable; else dataNotAvailable">
    <cl-share-characteristic-list
    [dateFormat]="dateFormat"
    [measures]="assetObject?.measures"
    [data]="data"
    (change)="measureChanged($event)"
    [tooltip]="tooltipCustomizer"
    [annotations]="annotations"
  >
  <div class="table-class">
    <div class="mt-6">
      <div *ngIf="isLoading">
        <div class="mt-2 flex justify-center">
          <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
        </div>
      </div>
      <div *ngIf="loggerData.length">
        <div class="row pr-1">
          <div
            class="action-panel-right flex items-center relative justify-end mr-16"
          >
            <!-- <cl-share-export-panel
              class="action-separator"
              (exportEmitter)="downloadFile($event)"
            ></cl-share-export-panel> -->
            <cl-share-grid-column-toggle
              [columnList]="rawColumns"
              (toggleColumns)="gridColumnToggle($event)"
            >
            </cl-share-grid-column-toggle>
          </div>
        </div>
           <div *ngIf="loggerData.length">
            <ngx-datatable
              #table
              [rows]="loggerData"
              [columns]="columns"
              [columnMode]="'force'"
              [loadingIndicator]="true"
              [headerHeight]="headerHeight"
              [rowHeight]="rowHeight"
              [scrollbarV]="true"
              [scrollbarH]="true"
              [selected]="selected"
              [rowIdentity]="getId"
              [reorderable]="false"
            >
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>

</cl-share-characteristic-list>

  </div>
  <ng-template #dataNotAvailable>
    <div *ngIf="isLoading">
      <div class="mt-2 flex justify-center">
        <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
      </div>
    </div>
    <div *ngIf="!isLoading" class="text-center">
      <label class="text-base font-bold pb-8">No Data available</label>
    </div>
  </ng-template>
  <ng-template #assetNameTemplate let-row="row" let-value="value" let-i="index">
    <span>{{ convertDateFormat(value) }}</span>
  </ng-template>
</div>
