import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cl-share-view',
  templateUrl: './share-view.component.html',
  styleUrls: ['./share-view.component.scss'],
})
export class ShareViewComponent implements OnInit {
  ngOnInit() {
  }
}
