import { ErrorHandler, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ApiProviderService } from "./services/api-provider.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private apiProvider: ApiProviderService){}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = this.apiProvider.getToken();
    let modifiedReq;
    if(userToken) {
      modifiedReq = req.clone({
        headers: req.headers.set('token',userToken),
      });
    } else {
      modifiedReq = req
    }
    return next.handle(modifiedReq);
  }
}
