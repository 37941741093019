import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastService } from '@cl/common/services/toast.service';
import moment from 'moment';
import { FormateDatePipe } from 'projects/clf-share/src/pipes/formate-date.pipe';
import { PropertyService } from 'projects/clf-share/src/services/property.service';
import { ShareService } from 'projects/clf-share/src/share.service';

@Component({
  selector: 'cl-share-composite-cards',
  templateUrl: './share-composite-cards.component.html',
  styleUrls: ['./share-composite-cards.component.scss']
})
export class ShareCompositeCardsComponent implements OnInit , OnChanges {
  @Input() shipmentId: string;
  @Input() compositeShipmentData: any;
  @Output() refreshShipment = new EventEmitter<void>();
  @Output() selectedShipment = new EventEmitter<any>();
  dateFormat;
  isHideCardTip = false;
  constructor(
    // private router: Router,
     private shipmentService: ShareService,
     private toastService: ToastService,
     private propertyService: PropertyService,
     private formateDatePipe: FormateDatePipe
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.compositeShipmentData && this.shipmentId) {
      const shipment = this.compositeShipmentData.subShipments.find(
        (shipment) => shipment.id == this.shipmentId
      );
      if (shipment.status == 'Drafts') {
        const nonDraftedShipment = this.compositeShipmentData.subShipments.find(
          (shipment) => shipment.status !== 'Drafts'
        );
        if (nonDraftedShipment) {
          this.onSelect(nonDraftedShipment.id,nonDraftedShipment.entityTypeKey);
        }
      }
      this.dateFormat = this.propertyService.getSetting('ui.dateFormat');
    }
  }

  getAlphabet(index: number) {
    if (index > 25) return ' ';
    return String.fromCharCode(index + 'A'.charCodeAt(0));
  }
  onSelect(shipmentId: string,type:string) {
    this.selectedShipment.emit({id:shipmentId,type:type});
  }

  onCardsScroll() {
    this.isHideCardTip = true;
    setTimeout(() => {
      this.isHideCardTip = false;
    }, 1000);
  }

  refreshData(shipmentId: string, shipmentType: string) {
    this.shipmentService
      .refreshShipment(shipmentId, shipmentType)
      .subscribe((res) => {
        this.toastService.openTimerSnackBar('Page will reloaded in ', 5);
        setTimeout(() => {
          this.refreshShipment.emit();
        }, 5000);
      });
  }
  convertDateFormat(planned: any) {
    return planned ? this.formateDatePipe.transform(planned,this.dateFormat) : '';
  }
}
