import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiProviderService {
  domainPrima;
  partDomainURI;
  port;
  protocol;
  apiConfig = {
    cloudos: "",
    clfgraphapp: ""
  };
  token;

  constructor(private http: HttpClient) {
    this.getBaseURLs();
  }

  getBaseHost() {
    let hostname = window.location.hostname;
    let subdomain;
    let prima;
    if(hostname !== 'localhost') {
      if(hostname.indexOf('cloudleaf') > -1) {
        subdomain = hostname.split('.cloudleaf.io')[0];
        prima = subdomain.split('-share')[0];
        this.domainPrima = prima;
        this.partDomainURI = '.cloudleaf.io';
      } else if(hostname.indexOf('parkoursc') > -1) {
        subdomain = hostname.split('.parkoursc.com')[0];
        prima = subdomain.split('-share')[0];
        this.domainPrima = prima;
        this.partDomainURI = '.parkoursc.com';
      }else {
        subdomain = hostname.substring(0,hostname.indexOf("."));
        prima = subdomain.split('-share')[0];
        this.domainPrima = prima;
        this.partDomainURI =hostname.split(prima + '-share')[1];
      }
      this.port = window.location.port;
      this.protocol = window.location.protocol;
    } else {
      this.domainPrima = environment.domain;
      this.partDomainURI = environment.shareUrl.split(this.domainPrima)[1];
      this.port = '';
      this.protocol = 'https:';
    }
  }

  getBaseURLs() {
    this.getBaseHost();
    let port = (this.port == '') ? '' : ":" + this.port;
    let graphURI = this.protocol + "//" + this.domainPrima+'-graph'+this.partDomainURI + port;
    let cloudURI = this.protocol + "//" + this.domainPrima+this.partDomainURI + port;
    this.apiConfig = {
      clfgraphapp: graphURI + "/clfgraphapp/",
      cloudos: cloudURI + "/cloudos/"
    }
  }
  getBaseURLDomain() {
    let hostname = window.location.hostname;
    let domain = environment.domain;
    let subdomain;
    if(hostname !== 'localhost') {
      if(hostname.indexOf('cloudleaf') > -1) {
        subdomain = hostname.split('.cloudleaf.io')[0];
      } else if(hostname.indexOf('parkoursc') > -1) {
        subdomain = hostname.split('.parkoursc.com')[0];
      }
      domain = subdomain.split('-')[0];
    }
    return domain;
  }

  getAPIUrl(appName) {
    if(appName){
      return this.apiConfig[appName];
    }
  }

  setToken(token) {
      this.token = token;
  }

  getToken() {
    return this.token;
  }
}
