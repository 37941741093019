import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ShareRoutingModule } from './share.routing.module';
import { ShareShipmentComponent } from './share-shipment/share-shipment.component';
import { ShareViewComponent } from './share-view/share-view.component';
import { HttpStatusInterceptor } from '../http-status-interceptor';
import { MaterialModule } from '@cl/@core/shell/material.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ShareShellComponent } from './share-shell.component';
import { ShareShipmentDetailsSummaryComponent } from './share-shipment/share-shipment-details-summary/share-shipment-details-summary.component';
import { ShareDetailsMapComponent } from './share-shipment/share-details-map/share-details-map.component';
import { ShareIncludedAssetsComponent } from './share-shipment/share-included-assets/share-included-assets.component';
import { ShareSummaryDataComponent } from './share-shipment/share-summary-data/share-summary-data.component';
import { ShareRoutePlanComponent } from './share-shipment/share-route-plan/share-route-plan.component';
import { ShareTrackingProgressComponent } from './share-shipment/share-tracking-progress/share-tracking-progress.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmOverlays } from 'agm-overlays';
import { AgmCoreModule } from "@agm/core";
import { ShareEnvConditionsComponent } from './share-shipment/share-details-map/share-env-conditions/share-env-conditions.component';
import { ShareDatePickerComponent } from './share-shipment/share-details-map/share-date-picker/share-date-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ShareExportPanelComponent } from './share-shipment/share-details-map/share-export-panel/share-export-panel.component';
import { ShareCharacteristicListComponent } from './share-shipment/share-details-map/share-characteristic-list/share-characteristic-list.component';
import { ShareLineChartComponent } from './share-shipment/share-details-map/share-line-chart/share-line-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MeasurePipe } from '../pipes/measure.pipe';
import { ShareGridColumnToggleComponent } from './share-shipment/share-details-map/share-grid-column-toggle/share-grid-column-toggle.component';
import { PersistenceService } from '../services/sh-persistence.service';
import { FormateDatePipe } from '../pipes/formate-date.pipe';
import { TokenInterceptor } from '../http-token-interceptor';
import { ShAlertIconComponent } from './share-shipment/sh-alert-icon/sh-alert-icon.component';
import { SearchPipe } from '../pipes/search.pipe';
import { ShareShipmentTrackerComponent } from './share-shipment/share-shipment-tracker/share-shipment-tracker.component';
import { ShareHtmlTooltipComponent } from './share-shipment/html-tooltip/html-tooltip.component';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { ShareCompositeCardsComponent } from './share-shipment/share-composite-cards/share-composite-cards.component';

@NgModule({
  declarations: [
    ShareShipmentComponent,
    ShareViewComponent,
    ShareShipmentDetailsSummaryComponent,
    ShareDetailsMapComponent,
    ShareIncludedAssetsComponent,
    ShareSummaryDataComponent,
    ShareRoutePlanComponent,
    ShareTrackingProgressComponent,
    ShareEnvConditionsComponent,
    ShareShipmentTrackerComponent,
    ShareDatePickerComponent,
    ShareExportPanelComponent,
    ShareGridColumnToggleComponent,
    ShareCharacteristicListComponent,
    ShareHtmlTooltipComponent,
    ShareLineChartComponent,
    ShAlertIconComponent,
    MeasurePipe,
    SearchPipe,
    TruncatePipe,
    FormateDatePipe,
    ShareCompositeCardsComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ShareRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AgmOverlays,
    NgxDatatableModule,
    NgApexchartsModule,
    NgxDaterangepickerMd.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBFrngKBmi7UwDVsNcRmOPnOVpaUbJYbn0"
    }),
    RouterModule.forRoot([], {useHash: true})
  ],
  providers: [
    PersistenceService,
    FormateDatePipe,
    { provide: HTTP_INTERCEPTORS, useExisting: HttpStatusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [ShareShellComponent],
})
export class ShareModule { }
