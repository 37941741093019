import {
  Component,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  OnInit,
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
// import { GatewayService } from '../../gateway.service';
import { PropertyService } from 'projects/clf-share/src/services/property.service';

export interface DatePickerRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Component({
  selector: 'cl-share-date-picker',
  templateUrl: './share-date-picker.component.html',
  styleUrls: ['./share-date-picker.component.scss'],
})
export class ShareDatePickerComponent {
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  @Input('dateRange') selectedDateRange: DatePickerRange;
  @Input('ranges') ranges: any;
  @Input() datePickerClass: any;
  @Input() displayFormat: any;
  @Input() singleDatePicker: any;
  @Input() disablePreviousdates: any;
  @Input('timePickerSeconds') timePickerSeconds: boolean = true;
  @Input ('timePicker24Hour') timePicker24Hour: boolean = true;
  @Input ('minDate') minDate: moment.Moment;
  @Input ('maxDate') maxDate: moment.Moment;
  @Input() placeholder = '';
  @Input() timePicker = true;

  
  addClass: any;

  locale : any = {
    format: 'MM/DD/YYYY',
    displayFormat: 'MM/DD/YYYY hh:mm:ss',
    weekLabel: 'W',
    customRangeLabel: 'Custom range',
    direction: 'ltr'
  };
  @Output('dateChanged') onDateChange: EventEmitter<DatePickerRange> = new EventEmitter<DatePickerRange>();

  constructor(private propertyService: PropertyService) {
  }

  datesUpdated(daterange: any){
    // console.log('daterange', daterange, 'selectedDateRange', this.selectedDateRange);
    this.onDateChange.emit(this.selectedDateRange);// this is modified to selectedDateRange, since it is not passing the updated value in dateRange
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  ngOnChanges(changes: any) {
    /* this.locale.displayFormat = _.cloneDeep((this.displayFormat || 'MM/DD/YYYY hh:mm:ss').slice());
    this.addClass = _.cloneDeep((this.datePickerClass || []).slice());
    if(this.disablePreviousdates){
      this.minDate = moment(this.disablePreviousdates);
    }else{
      this.minDate = null
    } */
    let dateformat:any = this.propertyService.getSetting('ui.dateFormat');
    let updatedDate = dateformat.replace('dd', 'DD')
    if(dateformat){
      this.timePicker24Hour = !updatedDate.includes('a');
      updatedDate = updatedDate.split(':ss').join('') //Removing seconds across application
      let formattedDate = updatedDate; //_.cloneDeep((this.displayFormat || updatedDate).slice());
      if(!this.timePicker && (formattedDate.includes('h') || formattedDate.includes('H'))){
        if(formattedDate.includes('h')){
          formattedDate = formattedDate.split(' h')[0];
        }else{
          formattedDate = formattedDate.split(' H')[0];
        }
      }
      this.locale.displayFormat = formattedDate;
      this.addClass = _.cloneDeep((this.datePickerClass || []).slice());
      if(this.disablePreviousdates){
        this.minDate = moment(this.disablePreviousdates);
      }else{
        this.minDate = null
      }
    }
  }
}
