import { Injectable } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { InputField } from '../types/form-rendering-engine.type';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormService {
  constructor() {}

  public orderFields(fields: any[], orderBy: string) {
    if (!fields.length) return [];

    fields = fields.sort((a: any, b: any) => b[orderBy] - a[orderBy]);

    let currentLargestOrder = fields[0][orderBy];

    const sortedArray: any = [];
    fields.forEach((field: any) => {
      if (field[orderBy] < 0) {
        field[orderBy] = ++currentLargestOrder;
      } else {
        sortedArray.unshift(field);
      }
    });

    fields.splice(0, sortedArray.length);
    fields.unshift(...sortedArray);

    return [...fields];
  }

  public createFormGroup(fields: InputField[]) {
    const group: any = {};

    fields.forEach((field) => {
      const validator = [];
      if (field.required) validator.push(Validators.required);
      if (field.regex) validator.push(Validators.pattern(field.regex));

      group[field.id] = new FormControl(
        {
          value: field.value || field.defaultValue,
          disabled: field.isDisabled,
        },
        validator
      );
    });

    return new FormGroup(group);
  }
}
